window.customElements.define("elm-tooltip", class extends HTMLElement {
    // Base custom element stuff
    constructor() {
        super();
        this._targetNode = document.createElement('div');
    }
    connectedCallback() {
        const trigger = document.querySelector(this.getAttribute("data-trigger"))
        this._targetNode.classList.add('hidden')

        trigger.addEventListener('mouseover', () => {
            this._targetNode.classList.remove('hidden')
            const { x, y } = this.getBoundingClientRect();
            this._targetNode.style["transform"] = `translate3d(${x}px, ${y}px, 0px)`;
        }
        )

        trigger.addEventListener('mouseleave', () =>
            this._targetNode.classList.add('hidden')
        )

        document.querySelector(this.getAttribute("data-target-selector")).appendChild(this._targetNode);
    }

    disconnectedCallback() {
        document.querySelector(this.getAttribute("data-target-selector")).removeChild(this._targetNode);
    }

    // Re-implementations of HTMLElement functions
    get childNodes() {
        return this._targetNode.childNodes;
    }

    replaceData(...args) {
        return this._targetNode.replaceData(...args);
    }

    removeChild(...args) {
        return this._targetNode.removeChild(...args);
    }

    insertBefore(...args) {
        return this._targetNode.insertBefore(...args);
    }
    appendChild(...args) {
        // To cooperate with the Elm runtime
        requestAnimationFrame(() => {
            return this._targetNode.appendChild(...args);
        });
    }
});