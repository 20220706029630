import { builderInit } from "./builderInit";
import { editorSetup } from "./editorSetup";
import { fillerInit } from "./fillerInit";
export const tinymceEditorIframeId = "mce_0_ifr";


const tinymceContentCSS = require("!css-loader?exportType=string!sass-loader!../tinymce-content.scss").default;
const fontsCSS = require("!css-loader?exportType=string!sass-loader!../../static/fonts.css").default;

const initInstanceCallback =
  ({
    app,
    isBuilder,
    readOnly,
    styleFormats,
    editorBookmark,
  }) =>
    (editor) => {
      const isFiller = !isBuilder;

      if (isBuilder) {
        builderInit({
          editor,
          readOnly,
          app,
          styleFormats,
          editorBookmark,
        });
      } else if (isFiller) {
        fillerInit({ app, editor, styleFormats });
      }

      // Use `ctrl+13` because for some reason `ctrl+enter` doesn't work
      // https://stackoverflow.com/questions/35949264/tinymce-how-to-add-shortcut-ctrlenter
      editor.addShortcut("ctrl+13", "Insert page break", "mcePageBreak");

      // TeTo uses ctrl+k for the ActionPalette (menu), so prevent
      // the link plugin from responding to the same shortcut.
      // According to the documentation it should have worked with:
      //   editor.shortcuts.remove("ctrl+k");
      // however after inspection it seems the uppercase ascii code
      // and abstract alias must be used.
      editor.shortcuts.remove("meta+75");
    };

//--------------------------------------------------------------------------------

export const getInitConfig = ({
  app,
  isBuilder,
  readOnly,
  editorBookmark,
  styleCss,
  fontFormats,
  locale,
  styleFormats,
  tinyMceFormats,
}) => ({
  selector: isBuilder ? "#builder-editor textarea" : "#filler-editor textarea",
  locale, // Defaults to en_US
  promotion: false,
  mobile: { theme: "silver" },
  menubar: "edit format table tools help insert",
  toolbar:
    "undo redo " +
    "| searchreplace copy cut paste " +
    "| alignleft aligncenter alignright alignjustify " +
    "| bullist numlist " +
    "| bold italic " +
    "| styles removeformat " +
    "| blockquote " +
    "| indent outdent " +
    "| table " +
    "| nonbreaking " +
    "| forecolor backcolor " +
    "| link unlink " +
    "| fontselect " +
    "| pageBreak " +
    (isBuilder ? "| labelsStyle" : "") +
    (isBuilder ? "| labelsLayout" : "") +
    (isBuilder ? "| toggleLabels" : "") +
    "| splitTable" +
    "| whitespace",
  paste_merge_formats: false,
  toolbar_mode: "sliding",
  resize: false,
  entity_encoding: "raw", // Change &nbsp; et al into unicode for xml compatibility.
  element_format: "xhtml", // Force <br> to become <br /> that is valid XML to avoid breaking the server side XML parser.
  extended_valid_elements:
    // Allow tags with custom attributes inside p's
    "*[data-teto-field|data-teto-template|data-teto-field-option|data-teto-template-version|data-teto-transient-box-outer|data-teto-transient-box-border|data-teto-transient-label-container|data-teto-transient-box-label|data-teto-transient-box-inner]",
  content_style: styleCss + '\n' + fontsCSS + '\n' + tinymceContentCSS,
  // content_css: [
  //   // Date.now() recommended here: https://www.tinymce.com/docs/configure/content-appearance/#anoteregardingbrowsercaching
  //   "/tinymce-content.css?" + Date.now(),
  //   "/fonts.css?" + Date.now(),
  // ],
  // All fonts are coming with the tinyMCE library by default, with the exception of
  // "Source Sans Pro"
  // Notice that his matches the ttf files provided to the TeTo.DocumentExporter.Engine,
  // such that PDF and Word files render correctly with the same fonts as the web page.
  font_formats: fontFormats.map((f) => f.title + "=" + f.format).join(";"),
  branding: false,
  plugins: [
    "table",
    "lists",
    "advlist",
    "link",
    "nonbreaking",
    "whitespace",
    "searchreplace"
  ],
  formats: tinyMceFormats,
  style_formats: styleFormats,
  setup: editorSetup({ isBuilder }),
  skin: "tinymce-5",
  body_class: isBuilder ? "new-labels-look-and-feel old-labels-layout" : "",
  contextmenu: (isBuilder ? "comment | table" : "table"),
  object_resizing: false,
  init_instance_callback: initInstanceCallback({
    app,
    isBuilder,
    readOnly,
    editorBookmark,
    styleFormats,
  }),
});
