import { tinymceEditorIframeId } from "./tinymceinit";

// make a set to keep all non propagating element references
const nonPropagatingClickElementsSet = new Set();

//
//
const overrideClickForOuterBoxes = (editor) => () => {
  // get the editor's body
  const editorBody = editor.getDoc().querySelector("body");

  // find all the outer transient boxes
  const outerTransientBoxes = [
    ...editorBody.querySelectorAll("[data-teto-transient-box-outer]"),
  ];

  // filter the elements that have not been handled yet
  const unhandledOuterTransientBoxes = outerTransientBoxes.filter(
    (outerTransientBox) =>
      !nonPropagatingClickElementsSet.has(outerTransientBox)
  );

  // modify the onclick handler to the unhandled elements
  unhandledOuterTransientBoxes.forEach(stopPropagationOnClick);

  // remove elements in the set that do not exist in the DOM anymore
  [...nonPropagatingClickElementsSet]
    .filter((el) => !document.body.contains(el))
    .forEach((el) => nonPropagatingClickElementsSet.delete(el));

  // find all elements that are in the handled set but do not have the attribute anymore
  [...nonPropagatingClickElementsSet]
    .filter(
      (handledElement) =>
        !handledElement.hasAttribute("data-teto-transient-box-outer")
    )
    .forEach((handledElement) => {
      // undo the stop progagation
      handledElement.onclick = null;
      nonPropagatingClickElementsSet.delete(handledElement);
    });
};

//
// prevent propagation of the onclick handler of an element
const stopPropagationOnClick = (element) => {
  const oldHandler = element.onclick;

  element.onclick = (event) => {
    event.stopPropagation();

    // if the active element doesn't have the tinymce iframe id
    if (document.activeElement.id !== tinymceEditorIframeId) {
      // set the focus on the iframe
      const iframeElement = document.getElementById(tinymceEditorIframeId);
      if (iframeElement !== null) {
        iframeElement.focus();
      }
      // we do that because if the focus is outside the editor, key events like
      // "backspace" or "delete" will not be handled by the editor
      // for example we won't be able to delete a selected reference
    }

    if (typeof oldHandler === "function") {
      oldHandler(event);
    }
  };

  // mark the element as handled by adding it to the set
  nonPropagatingClickElementsSet.add(element);
};

const nextSiblings = (elem) => {
  let siblings = [];

  while (elem = elem.nextElementSibling) {
    siblings.push(elem);
  }
  return siblings;
};

const clearTableRows = (elem) => {
  const tBody = elem.querySelector('tbody');

  if (tBody) {
    tBody.innerHTML = "";
  } else {
    const rows = elem.querySelectorAll('tr');

    rows.forEach((row) => {
      elem.removeChild(row);
    })
  }
}

export const editorSetup =
  ({ isBuilder }) =>
    (editor) => {
      editor.ui.registry.addButton("pageBreak", {
        text: "Page Break",
        onAction: () =>
          editor.insertContent(
            '<div class="page-break" contenteditable="false"></div>'
          ),
      });

      editor.ui.registry.addButton("labelsStyle", {
        text: "Labels style",
        onAction: () => {
          let body = editor.getBody();

          body.classList.toggle('old-labels-look-and-feel');
          body.classList.toggle('new-labels-look-and-feel');
        }
      });

      editor.ui.registry.addButton("labelsLayout", {
        text: "Labels layout",
        onAction: () => {
          let body = editor.getBody();

          body.classList.toggle('old-labels-layout');
        }
      });

      editor.ui.registry.addButton("toggleLabels", {
        text: "Toggle labels",
        onAction: () => {
          let body = editor.getBody();

          let elements = body.querySelectorAll('[data-teto-transient-box-label]');

          elements.forEach(elem => elem.classList.toggle('hide'));
        }
      });

      editor.ui.registry.addButton("splitTable", {
        text: "Split table",
        onAction: () => {
          const selectedNode = editor.selection.getNode();

          const table = selectedNode.closest('table');

          if (table) {
            const clonedTable = table.cloneNode(true);
            const clonedTableContents = clonedTable.querySelector('tbody') ? clonedTable.querySelector('tbody') : clonedTable;

            clearTableRows(clonedTable);

            const selectedRow = selectedNode.closest('tr');
            const parent = selectedRow.parentNode;

            const rowsAfter = nextSiblings(selectedRow);

            rowsAfter.forEach((row) => {
              parent.removeChild(row);

              clonedTableContents.appendChild(row)
            })

            table.after(clonedTable);
          }
        }
      })

      if (isBuilder) {
        // when the editor is initialized
        editor.on("init", overrideClickForOuterBoxes(editor));
        // when the editor has lines added/removed
        editor.on("change", overrideClickForOuterBoxes(editor));
        // when we change the content of the editor via Ports
        editor.on("builderHtmlChange", overrideClickForOuterBoxes(editor));
      }
    };
