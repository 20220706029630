/* eslint-disable fp/no-class,fp/no-this,fp/no-mutation,fp/no-throw,no-global-assign,fp/no-loops */

const { locale } = require("../helpers");
const flatpickr = require("flatpickr").default;
// eslint-disable-next-line no-unused-vars
const flatpckrNo = require("flatpickr/dist/l10n/no");

const datePickerLocale = locale.startsWith("en") ? "en" : "no";

class DatePicker extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    const picker = flatpickr(this, {
      maxDate: this.dataset.max,
      minDate: this.dataset.min,
      locale: datePickerLocale,
      dateFormat: "Y-m-d",
      appendTo: document.getElementById(this.dataset.calendarContainer)
    });

    this.addEventListener("keydown", (e) => {
      if (e.key === "Tab") {
        picker.close();
      }
    });

    this._picker = picker;
  }

  disconnectedCallback() {
    this._picker.destroy();
    this._flatpickr = undefined;
  }
}

customElements.define("date-picker", DatePicker);
/* eslint-enable */
