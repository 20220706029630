import { tetoShortcut } from "./helpers";
const debounce = require("lodash.debounce");

export const fillerInit = ({ app, editor, styleFormats }) => {
  const handleDirty = debounce(() => {
    // TODO: Avoid sending the contents if there are no relevant changes.
    editor.setDirty(false);
    // When testing, for large templates ELM uses around 300ms to process receiving html contents
    if (document.body.contains(editor.targetElm)) {
      app.ports.fillerUpdateHtml.send({ html: editor.getContent(), isUpdateBeforeSaving: false});
    }
  }, 500);

  const matchStyleFormat = (index = 0) => {
    if (index < styleFormats.length) {
      if (editor.formatter.match(styleFormats[index].format)) {
        return true;
      } else {
        return matchStyleFormat(index + 1);
      }
    } else {
      return false;
    }
  };

  editor.on("Dirty", handleDirty);
  editor.on("NodeChange", () => {
    if (editor.selection.getNode().tagName === "P" && !matchStyleFormat()) {
      editor.selection.getNode().classList.add("Normal");
    }
  });

  editor.on("Keydown", (e) => {
    tetoShortcut(app)(e);
  });
};
