class DynamicInput extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.resizable();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    this.resizable();
  }

  static get observedAttributes() {
    return ["input-id", "min-width", "max-width", "char-length"];
  }

  resizable() {
    const inputId = this.getAttribute("input-id");
    const minWidth = this.getAttribute("min-width");
    const maxWidth = this.getAttribute("max-width");
    const charLength = this.getAttribute("char-Length");

    const el = document.querySelector(inputId);
    const int = Number(charLength);

    if (el) {
      el.style.minWidth = minWidth;
      el.style.maxWidth = maxWidth;
    }

    function resize() {
      if (el) {
        el.style.width = (el.value.length + 1) * int + "px";
      }
    }

    var e = "keyup,keypress,change,click".split(",");

    if (el) {
      e.forEach((ev) => el.addEventListener(ev, resize, false));
    }

    resize();
  }
}

customElements.define("dynamic-input", DynamicInput);
