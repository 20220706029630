window.customElements.define("elm-portal", class extends HTMLElement {
    // Base custom element stuff
    constructor() {
        super();
        this._targetNode = document.createElement('div');
    }

    connectedCallback() {
        document.querySelector(this.getAttribute("data-target-selector")).appendChild(this._targetNode);
    }

    disconnectedCallback() {
        document.querySelector(this.getAttribute("data-target-selector")).removeChild(this._targetNode);
    }

    // Re-implementations of HTMLElement functions
    get childNodes() {
        return this._targetNode.childNodes;
    }

    replaceData(...args) {
        return this._targetNode.replaceData(...args);
    }

    removeChild(...args) {
        return this._targetNode.removeChild(...args);
    }

    insertBefore(...args) {
        return this._targetNode.insertBefore(...args);
    }
    appendChild(...args) {
        // To cooperate with the Elm runtime
        requestAnimationFrame(() => {
            return this._targetNode.appendChild(...args);
        });
    }
});