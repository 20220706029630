/* eslint-disable fp/no-class,fp/no-this,fp/no-mutation,fp/no-throw,no-global-assign,fp/no-loops */

const debounce = require("lodash.debounce");

class ScrollableList extends HTMLElement {
  constructor() {
    super();

    this._id = `scrollable-list-${Math.random()}`;

    this._leftArrowId = this._id + "-left-arrow";
    this._rightArrowId = this._id + "-right-arrow";
  }

  connectedCallback() {
    const div = this.querySelector(".scrollable-list-wrapper");
    if (div === null) {
      throw new Error(
        "ScrollableList: no div with class 'scrollable-list-wrapper' found"
      );
    }

    this._scrollable_wrapper = div;

    // whenever the element is scrolled, re-render
    this._scrollable_wrapper.addEventListener(
      "scroll",
      // debounce it, even though the user should not be able to manually scroll
      // in any case, we don't need to re-render on every scroll event
      // once every 50ms is enough
      debounce(() => {
        this.render();
      }),
      50
    );

    // to handle resizing situations
    const resizeObserver = new ResizeObserver(() => {
      this.render();
    });
    resizeObserver.observe(this);

    this.render();
  }

  addRightArrow() {
    const arrowRightElement = document.createElement("span");
    arrowRightElement.id = this._rightArrowId;
    arrowRightElement.classList.add("arrow");
    arrowRightElement.style.right = "0";
    this.appendChild(arrowRightElement);

    // add the ">" character to the arrow
    arrowRightElement.innerHTML = "&gt;";

    // when clicking the arrow, scroll the element to the right, so that the element that
    // wasn't visible before becomes visible
    arrowRightElement.addEventListener("click", () => {
      const width = this._scrollable_wrapper.getBoundingClientRect().width;
      this._scrollable_wrapper.scrollLeft += width;
    });
  }

  removeRightArrow() {
    const arrowRightElement = document.getElementById(this._rightArrowId);
    if (arrowRightElement !== null) {
      this.removeChild(arrowRightElement);
    }
  }

  addLeftArrow() {
    const arrowLeftElement = document.createElement("span");
    arrowLeftElement.id = this._leftArrowId;
    arrowLeftElement.classList.add("arrow");
    arrowLeftElement.style.left = "0";
    this.appendChild(arrowLeftElement);

    // add the "<" character to the arrow
    arrowLeftElement.innerHTML = "&lt;";

    // when clicking the arrow, scroll the element to the left, so that the element that
    // wasn't visible before becomes visible
    arrowLeftElement.addEventListener("click", () => {
      const scrollable_wrapper = this._scrollable_wrapper;
      const width = scrollable_wrapper.getBoundingClientRect().width;
      scrollable_wrapper.scrollLeft -= width;
    });
  }

  removeLeftArrow() {
    const arrowLeftElement = document.getElementById(this._leftArrowId);
    if (arrowLeftElement !== null) {
      this.removeChild(arrowLeftElement);
    }
  }

  render() {
    const scrollable_wrapper = this._scrollable_wrapper;
    const width = scrollable_wrapper.getBoundingClientRect().width;
    const contentWidth = scrollable_wrapper.scrollWidth;

    // if the contents are wider than the element
    // and we can scroll more to the right
    if (
      contentWidth > width &&
      scrollable_wrapper.scrollLeft <= contentWidth - width - 3
    ) {
      if (document.getElementById(this._rightArrowId) === null) {
        this.addRightArrow();
      }
    } else {
      if (document.getElementById(this._rightArrowId) !== null) {
        this.removeRightArrow();
      }
    }

    // if there's space to scroll to the left
    if (scrollable_wrapper.scrollLeft > 1) {
      // if it doesn't exist already, add the left arrow
      if (document.getElementById(this._leftArrowId) === null) {
        this.addLeftArrow();
      }
    } else {
      if (document.getElementById(this._leftArrowId) !== null) {
        this.removeLeftArrow();
      }
    }
  }
}

customElements.define("scrollable-list", ScrollableList);
/* eslint-enable */
