import tinymce from "tinymce";

import * as ElmDebugger from "elm-debug-transformer";

if (DEBUGTRANSFORMER) {
  ElmDebugger.register({ simple_mode: false, debug: false, limit: 1000000 });
}

// web components
import "./js/components/scrollable-list";
import "./js/components/date-picker";
import "./js/components/markdown-editor";
import "./js/components/elm-tooltip";
import "./js/components/elm-portal";
import "./js/components/dynamic-input";

import "@fortawesome/fontawesome-free/css/svg-with-js.min.css";
import "./index.scss";
import "./flatpickr.scss";
import "../static/fonts.css";
// Webpack configuration uses style-loader for sasss files by default.
import indexCssContents from "!css-loader?exportType=string!sass-loader!./index.scss";
import fillerHighlightCssContents from "!css-loader?exportType=string!sass-loader!./fillerHighlight.scss";

import { Elm } from "./App.elm";
import { setupPorts } from "./js/ports";
import {
  mutate,
  locale,
  OAuthGoogleStorageKey,
  tetoShortcut,
} from "./js/helpers";

// There are sometimes problems with TinyMce, and then live debugging is required.
// Make sure window.t is always available, allowing usage of window.t.activeEditor
// for interactive debugging/inspection.
// eslint-disable-next-line fp/no-mutation
window.t = tinymce;

const app = Elm.App.init({
  flags: {
    viewportWidth: window.innerWidth,
    viewportHeight: window.innerHeight,
    initialLocale: locale,
    oAuthJsonInStringEncodedAsValue: localStorage.getItem(
      OAuthGoogleStorageKey
    ),
    indexCssContents: indexCssContents,
    fillerHighlightCssContents: fillerHighlightCssContents,
  },
});

window.addEventListener("beforeunload", (e) => {
  const elem = document.querySelector("[data-unsaved]");
  const shouldStop = elem && JSON.parse(elem.dataset.unsaved);
  if (shouldStop) {
    app.ports.triggerSave.send(null);
    e.preventDefault();
    // Chrome requires returnValue to be set.
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
    mutate(e, "returnValue", "");
  }
});

// Whenever localStorage changes in another tab, report it if necessary.
window.addEventListener(
  "storage",
  function (event) {
    if (
      event.storageArea === localStorage &&
      event.key === OAuthGoogleStorageKey
    ) {
      app.ports.onOAuthGoogleChange.send(event.newValue);
    }
  },
  false
);

window.addEventListener("keydown", tetoShortcut(app));

//
// setup all Elm ports for the app
setupPorts(app);
