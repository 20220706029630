//
// helper to find all text "nodes" in a dom element in a performant way
const textNodesUnder = (containerElement) => {
  const textNodesFound = [];
  const walker = document.createTreeWalker(
    containerElement,
    NodeFilter.SHOW_TEXT,
    null,
    false
  );

  let nextNode;

  while ((nextNode = walker.nextNode())) {
    textNodesFound.push(nextNode);
  }
  return textNodesFound;
};

// find all stext nodes in the domElement
// get all the space occurencies
// and replace them with <span class="converted-space">&nbsp;</span>
const convertAllSpacesToSpans = (domElement) => {
  const textNodes = textNodesUnder(domElement);

  textNodes
    // skip the text nodes that are inside the ".replaced-spaces" elements
    // because they have already been converted
    .filter((textNode) => {
      let parent = textNode.parentNode;
      return !parent.classList.contains("replaced-spaces");
    })
    .forEach((textNode) => {
      // crete a <span class="replaced-spaces"></span> element
      const spanReplacedSpaces = document.createElement("span");
      spanReplacedSpaces.classList.add("replaced-spaces");

      spanReplacedSpaces.innerHTML = textNode.textContent
        .split("")
        .map((str) => {
          if (str.charCodeAt(0) === 32) {
            return '<span class="converted-space">&nbsp;</span>';
          } else if (str.charCodeAt(0) === 160) {
            return '<span class="converted-space original-nbsp">&nbsp;</span>';
          } else {
            return str;
          }
        })
        .join("");

      textNode.replaceWith(spanReplacedSpaces);
    });
};

const convertAllSpansToSpaces = (domElement) => {
  // find all <span class="converted-space original-nbsp">&nbsp;</span>
  // and replace them with a &nbsp;
  const spanNbsps = [
    ...domElement.querySelectorAll("span.converted-space.original-nbsp"),
  ];
  spanNbsps.forEach((spanNbsp) => {
    spanNbsp.replaceWith(String.fromCharCode(160));
  });

  // find all <span class="converted-space">&nbsp;</span>
  // and replace them with a space
  const spaces = [...domElement.querySelectorAll("span.converted-space")];
  spaces.forEach((space) => {
    space.replaceWith(" ");
  });

  // replace all <span class="replaced-spaces"></span> with their text content as a text node
  const spans = [...domElement.querySelectorAll("span.replaced-spaces")];
  spans.forEach((span) => {
    span.replaceWith(span.textContent);
  });
};

const whiteSpaceAnnotation = (domElement) => {
  convertAllSpacesToSpans(domElement);

  const allHeaders = [...domElement.querySelectorAll("h1, h2, h3, h4, h5, h6")];
  const allListItems = [...domElement.querySelectorAll("li")];
  const allParagraphs = [...domElement.querySelectorAll("p")];
  // note : inside <td> tinymce doesn't differentiate between "enter" and "shift-enter"
  const allTableCells = [...domElement.querySelectorAll("td")];

  const allContainers = [
    ...allHeaders,
    ...allListItems,
    ...allParagraphs,
    ...allTableCells,
  ];

  allContainers.forEach((containerElement) => {
    const containerClassList = containerElement.classList;
    const containerChildren = [...containerElement.children];

    // reset the classes we used from the previous run
    containerClassList.remove("line-break-after");
    containerClassList.remove("line-break-float");

    // we use the float class when the container ends in an empty line with just a <br>
    // either just 1 <br> or 2 consecutive <br> at the end
    const endsWithEmptyLine =
      (containerChildren.length === 1 &&
        containerChildren[0].tagName === "BR") ||
      (containerChildren.length > 1 &&
        [...containerChildren]
          .slice(-2)
          .map((el) => el.tagName)
          .join("") === "BRBR");

    if (endsWithEmptyLine) {
      containerClassList.add("line-break-float");
    } else {
      containerClassList.add("line-break-after");
    }

    // if there are children, we need to check them
    if (containerChildren.length > 0) {
      // any <text> nodes before <br> should be replaced by a <span> with the same text
      // so we can style them with ::after
      const childNodes = [...containerElement.childNodes];

      childNodes
        .filter(
          (_node, index) =>
            // if the next node is a <br>
            childNodes[index + 1] &&
            childNodes[index + 1].tagName === "BR" &&
            // and this node is not a <span class="shift-enter-after">
            !(
              _node &&
              _node.classList &&
              _node.classList.contains("shift-enter-after")
            ) &&
            // and it's not before the last node
            index !== childNodes.length - 2
        )
        .forEach((node) => {
          const span = document.createElement("span");
          span.classList.add("shift-enter-after");
          // add it after the node
          node.parentNode.insertBefore(span, node.nextSibling);
        });
    }
  });

  return domElement;
};

const whiteSpaceAnnotationClear = (domElement) => {
  convertAllSpansToSpaces(domElement);

  // first replace all the <span class="shift-enter-after"> with <text>
  const spans = [...domElement.querySelectorAll("span.shift-enter-after")];
  spans.forEach((span) => {
    const text = document.createTextNode(span.textContent);
    span.replaceWith(text);
  });

  // the classes we added for the plugin
  const classesToRemove = ["line-break-after", "line-break-float"];

  // remove them all
  classesToRemove.forEach((classToRemove) => {
    [...domElement.querySelectorAll("." + classToRemove)].forEach((node) =>
      node.classList.remove(classToRemove)
    );
  });

  return domElement;
};

const startPlugin = ({ editorBodyInstance, shutDownListener }) => {
  const processedBody = whiteSpaceAnnotation(editorBodyInstance);
  processedBody.setAttribute("contenteditable", false);
  processedBody.classList.add("whitespace-active");
  processedBody.addEventListener("keydown", shutDownListener);
  return processedBody;
};

const shutDownPlugin = ({ editorBodyInstance, shutDownListener, button }) => {
  const processedBody = whiteSpaceAnnotationClear(editorBodyInstance);
  processedBody.setAttribute("contenteditable", "true");
  processedBody.removeEventListener("keydown", shutDownListener);
  processedBody.classList.remove("whitespace-active");
  button.setActive(false);
  return processedBody;
};

// a handler for the plugin button toggle
const handleButtonToggle = ({ button, editor }) => {
  // toggle the button state,
  // so the rest of the code works with the new state of the button
  button.setActive(!button.isActive());

  // clone the editor body, to work on it instead of the DOM element
  const editorBody = editor.getBody();
  const editorBodyClone = editorBody.cloneNode(true);

  const shutDownListener = (event) => {
    // cancel the key down because otherwise the focus of the editor will be at a
    // different place and the user will be confused on where the cursor is
    event.preventDefault();
    // we only use the key down to shut down the plugin. Then the user can type normally.
    shutDownPlugin({
      editorBodyInstance: editor.getBody(),
      shutDownListener,
      button,
    });
  };

  const editorBodyCloneProcessed = button.isActive()
    ? startPlugin({ editorBodyInstance: editorBodyClone, shutDownListener })
    : shutDownPlugin({
        editorBodyInstance: editorBodyClone,
        shutDownListener,
        button,
      });

  // at the next frame,
  // replace the editor body with the processed clone
  requestAnimationFrame(() => {
    editorBody.parentNode.replaceChild(editorBodyCloneProcessed, editorBody);
  });
};

const whiteSpacePlugin = (tinymce) => {
  tinymce.PluginManager.add("whitespace", (editor, _url) => {
    /*
     * Add a button that toggles the white space indicators
     */
    editor.ui.registry.addToggleButton("whitespace", {
      text: "White Space",
      active: false,
      onAction: (button) => {
        try {
          handleButtonToggle({ button, editor });
        } catch (whitespaceButtonActionError) {
          console.error(whitespaceButtonActionError);
        }
      },
    });

    /* Return the metadata for the plugin */
    return {
      getMetadata: () => ({
        name: "White space indicator",
        url: "",
      }),
    };
  });
};

export { whiteSpacePlugin, whiteSpaceAnnotationClear, whiteSpaceAnnotation };
