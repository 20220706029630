import { initDatePicker, tetoShortcut } from "./helpers";
import { getFillerDocumentHtml } from "./helpers";
import { scrollWithin } from "./helpers";
import { OAuthGoogleStorageKey } from "./helpers";
import { zenScrollTo } from "./helpers";
import { addId } from "./helpers";
import {
  datePickerLocale,
  dereferenceField,
  fillerValueReferenceRenderInstructions,
  getEditor,
  getFillerDocumentHeight,
  getParents,
  getTextWidth,
  isElementVisible,
  isHidden,
  mutate,
  parseHTML,
  reArrangeSignatureTables,
  sendEditorContentsToElm,
} from "./helpers";
import { getInitConfig } from "./tinymceinit";
import { whiteSpacePlugin } from "./whiteSpacePlugin";
import { ServerEventsClient } from "@servicestack/client";
import { deriveReferenceHtml, getWrapperElement } from "./builderInit";
import * as pdfjsLib from 'pdfjs-dist';
import EasyMDE from "easymde";

// pdfjsLib.GlobalWorkerOptions.workerSrc = '../node_modules/pdfjs-dist/build/pdf.worker.js';
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/4.2.67/pdf.worker.min.mjs`;

const debounce = require("lodash.debounce");

var serverSentEventsClient = undefined;

const getOAuthGoogle = (app) => () => {
  // The setTimeout here is "just" because it's also found in other projects,
  // such as the ELM SPA, however, I haven't seen any rationale for why that is
  // required.
  setTimeout(function () {
    app.ports.onOAuthGoogleChangeOrGet.send(
      localStorage.getItem(OAuthGoogleStorageKey)
    );
  }, 0);
};

const storeAndGetOAuthGoogle = (app) =>
  function (val) {
    const valAsJson = val === null ? null : JSON.stringify(val);
    if (val === null) {
      localStorage.removeItem(OAuthGoogleStorageKey);
    } else {
      // Notice that JSON.stringify makes it possible to inspect the stored json
      // in the browser debugger mode, but it also changes the stored value,
      // so it is no longer a json value, but json stored in a string,
      // and must be parsed as such in Elm.
      localStorage.setItem(OAuthGoogleStorageKey, valAsJson);
    }
    // Report report back as the single source of truth for OAuth.
    setTimeout(function () {
      app.ports.onOAuthGoogleChangeOrGet.send(valAsJson);
    }, 0);
  };

const getClipboardData = (app) => () => {
  navigator.clipboard.read().then((data => {
    if (data[0] && data[0].types[0]) {
      return data[0].getType(data[0].types[0])
    };
  }))
    .then(value => value.text())
    .then(JSON.parse)
    .then(value => app.ports.getClipboardDataReply.send(value));
}

const initServerSentEvents = (app) => () => {
  if (serverSentEventsClient !== undefined) {
    serverSentEventsClient.stop();
  }

  const channels = ["template"];
  const client = new ServerEventsClient("/backend", channels, {
    handlers: {
      updatedTemplate: info => {
        const parsedInfo = JSON.parse(info);
        app.ports.fillerUpdatedTemplate.send(parsedInfo);
      }
    },
    onException: (e) => { },                 // Invoked on each Error
    onReconnect: (e) => { }                  // Invoked after each auto-reconnect
  })
    .start();
  serverSentEventsClient = client;
};

const builderFocusOnEditor = (app) => (maybeBookmark) =>
  getEditor((editor) => {
    const tinyWindow = document.querySelector("#builder-editor iframe");
    if (!tinyWindow || !tinyWindow.contentDocument) {
      return;
    }

    const tiny = tinyWindow.contentDocument.getElementById("tinymce");
    if (!tiny) {
      return;
    }

    if (editor.bookmark !== undefined) {
      tiny.focus();
    }

    if (maybeBookmark) {
      try {
        editor.selection.moveToBookmark(maybeBookmark);
      } catch (err) {
        console.error(err);
        app.ports.reportFrontendError.send({
          module_: "builder",
          message: "editor.selection.moveToBookmark",
        });
      }
    }
  });

const builderMountEditor =
  (app) =>
    ({
      html,
      classFormats,
      readOnly,
      locale,
      editorBookmark,
      styleCss,
      fontFormats,
      styleFormats,
    }) =>
      requestAnimationFrame(() => {
        const editorElement = document.querySelector("#builder-editor textarea");

        if (!editorElement) {
          return;
        }

        if (tinymce.activeEditor) {
          // When removing, editor instance triggers Dirty event, which causes empty html to be sent to ELM, and this triggers
          // saving of an empty template
          tinymce.activeEditor.off("Dirty");
          tinymce.activeEditor.remove();
        }

        mutate(editorElement, "value", html);

        const tinyMceFormats = {};

        classFormats.forEach((format) => {
          mutate(tinyMceFormats, format.class, {
            block: format.block,
            attributes: { class: format.class },
            selector: format.selector,
          });
        });

        whiteSpacePlugin(tinymce);

        return tinymce.EditorManager.init(
          getInitConfig({
            app,
            isBuilder: true,
            readOnly,
            locale,
            editorBookmark,
            styleCss,
            fontFormats,
            styleFormats,
            tinyMceFormats,
          })
        );
      });

const fillerMountEditor =
  (app) =>
    ({ html, classFormats, locale, styleCss, fontFormats, styleFormats }) =>
      requestAnimationFrame(() => {
        const editorElement = document.querySelector("#filler-editor textarea");

        if (!editorElement) {
          return;
        }

        if (tinymce.activeEditor) {
          // When removing, editor instance triggers Dirty event, which causes empty html to be sent to ELM, and this triggers
          // saving of an empty template
          tinymce.activeEditor.off("Dirty");
          tinymce.activeEditor.remove();
        }

        mutate(editorElement, "value", html);

        const tinyMceFormats = {};

        classFormats.forEach((format) => {
          mutate(tinyMceFormats, format.class, {
            block: format.block,
            attributes: { class: format.class },
            selector: format.selector,
          });
        });

        whiteSpacePlugin(tinymce);

        return tinymce.EditorManager.init(
          getInitConfig({
            app,
            isBuilder: false,
            locale,
            styleCss,
            fontFormats,
            styleFormats,
            tinyMceFormats,
          })
        );
      });

const fillerUnmountEditor = (app) => () =>
  getEditor((editor) => {
    // When removing, editor instance triggers Dirty event, which causes empty html to be sent to ELM, 
    // and this triggers saving of an empty template
    editor.off("Dirty");
    const editorElm = editor.targetElm
    editor.remove();
    // Hide the textarea
    editorElm.style.display = 'none';
  });

const builderToggleLabels = () => {
  getEditor((editor) => {
    let body = editor.getBody();

    let elements = body.querySelectorAll('[data-teto-transient-box-label]');

    elements.forEach(elem => elem.classList.toggle('hide'));
  });
};

const builderToggleLabelsStyle = () => {
  getEditor((editor) => {
    let body = editor.getBody();

    body.classList.toggle('old-labels-look-and-feel');
    body.classList.toggle('new-labels-look-and-feel');
  });
};

const builderToggleLabelsLayout = () => {
  getEditor((editor) => {
    let body = editor.getBody();

    body.classList.toggle('old-labels-layout');
  });
};

const builderHtmlChange = debounce(
  (htmlChanges) =>
    getEditor((editor) =>
      requestAnimationFrame(() => {
        htmlChanges.forEach(({ selector, value }) =>
          editor.dom
            .select(selector)
            .forEach((elem) => mutate(elem, "innerHTML", value))
        );

        editor.dispatch("builderHtmlChange");
      })
    ),
  1000
);

const builderUpdateVersion = (version) =>
  getEditor((editor) =>
    editor.dom
      .select("[data-teto-template-version]")
      .forEach((el) => mutate(el.dataset, "tetoTemplateVersion", version))
  );

const builderUpdateReferenceLabelAndName = app => ({ referenceId, referenceName, referenceLabel }) =>
  getEditor((editor) => {
    editor.dom
      .select(`[id="${referenceId}"]`)
      .forEach((el) => mutate(el.dataset, "tetoReferenceName", referenceName));

    editor.dom
      .select(`[data-teto-transient-box-label="${referenceId}"]`)
      .forEach((el) => {
        mutate(el, "innerHTML", referenceLabel)
      });

    sendEditorContentsToElm(app)(editor, [], [], false);
  }
  );

const fillerGetDocumentHeight = (app) => () =>
  app.ports.fillerReceivedDocumentHeight.send(getFillerDocumentHeight());

const fillerRenderValues = (app) =>
  ({ edits, replyWithRenderedDocument }) => {
    const fillerDocumentHtml = getFillerDocumentHtml();
    if (!fillerDocumentHtml) {
      // Avoid a missing iframe, such as when in mobile mode showing fields only.
      return;
    }

    edits.forEach(({ instruction, selector, data }) => {
      const fn = fillerValueReferenceRenderInstructions[instruction];

      if (!fn) {
        return console.error("Invalid instruction specified: " + instruction);
      }
      Array.from(fillerDocumentHtml.querySelectorAll(selector)).forEach(
        (elem) => {
          fn(data)(elem);
        }
      );
    });

    fillerDocumentHtml
      .querySelectorAll("[data-teto-root]")[0]
      .classList.remove("fillerDocumentWithHiddenChildren");

    app.ports.fillerRenderValuesReply.send(
      replyWithRenderedDocument
        ? fillerDocumentHtml.children[0].outerHTML
        : null
    );

    app.ports.fillerReceivedDocumentHeight.send(getFillerDocumentHeight());
  };

const fillerHighlightReferences =
  (app) =>
    ({ referenceInfo: { guid, maybeListRow, maybeProperty }, shouldScroll }) =>
      requestAnimationFrame(() => {
        const fillerDocumentHtml = getFillerDocumentHtml();

        // Remove all "current" classes.
        Array.from(fillerDocumentHtml.querySelectorAll(".current")).forEach(
          (elem) => elem.classList.remove("current")
        );
        Array.from(
          fillerDocumentHtml.querySelectorAll(".closest-parent")
        ).forEach((elem) => elem.classList.remove("closest-parent"));
        Array.from(
          fillerDocumentHtml.querySelectorAll(".top-level-parent")
        ).forEach((elem) => elem.classList.remove("top-level-parent"));

        const guidSelectorPart = `[data-teto-field="${guid}"]`;
        const listRowSelectorPart =
          maybeListRow != null ? `[data-teto-list-index="${maybeListRow}"]` : "";
        const propertySelectorPart =
          maybeProperty != null
            ? `[data-teto-field-property="${maybeProperty}"]`
            : "";

        const selector =
          guidSelectorPart + listRowSelectorPart + propertySelectorPart;

        const references = Array.from(
          fillerDocumentHtml.querySelectorAll(selector)
        );

        references.forEach((elem) => elem.classList.add("current"));
        references.forEach((elem) => {
          const parents = getParents(elem, "[data-teto-field]");
          const closest = parents[0];

          const farthest = parents[parents.length - 1];

          if (isElementVisible(elem)) {
            if (closest) {
              closest.classList.add("closest-parent");
            }

            if (farthest && parents.length !== 1) {
              farthest.classList.add("top-level-parent");
            }
          }
        });

        const documentBody = fillerDocumentHtml.body;
        const firstReference = references.find((x) => x.offsetParent !== null);

        if (!documentBody) {
          app.ports.reportFrontendError.send({
            module_: "filler",
            message:
              "app.ports.fillerHighlightReferences: Missing required iframe document.",
          });
        }

        if (!firstReference) {
          return console.info(
            "Ignoring scroll to field with no visible references."
          );
        }

        if (shouldScroll) {
          if (firstReference.dataset.tetoFieldOption) {
            // Find a visible option to scroll to
            const optionToScrollTo = references.find((el) => !isHidden(el));

            if (!optionToScrollTo) {
              return console.info(
                "Ignoring scroll to option with no visible references."
              );
            }
            return scrollWithin(
              document.querySelector("#filler-document-wrapper"),
              optionToScrollTo
            );
          } else {
            return scrollWithin(
              document.querySelector("#filler-document-wrapper"),
              firstReference
            );
          }
        }

        app.ports.fillerReceivedDocumentHeight.send(getFillerDocumentHeight());
      });

const fillerHighlightHoveredReferences =
  (app) =>
    ({ guid, maybeListRow, maybeProperty }) => {
      requestAnimationFrame(() => {
        const fillerDocumentHtml = getFillerDocumentHtml();

        Array.from(fillerDocumentHtml.querySelectorAll(".hovered")).forEach(
          (elem) => elem.classList.remove("hovered")
        );

        const guidSelectorPart = `[data-teto-field="${guid}"]`;
        const listRowSelectorPart =
          maybeListRow != null ? `[data-teto-list-index="${maybeListRow}"]` : "";
        const propertySelectorPart =
          maybeProperty != null
            ? `[data-teto-field-property="${maybeProperty}"]`
            : "";

        const selector =
          guidSelectorPart + listRowSelectorPart + propertySelectorPart;

        const references = Array.from(
          fillerDocumentHtml.querySelectorAll(selector)
        );

        references.forEach((elem) => elem.classList.add("hovered"));

        app.ports.fillerReceivedDocumentHeight.send(getFillerDocumentHeight());
      });
    };

const fillerRemoveHighlightHoveredReferences = (app) => (_) => {
  requestAnimationFrame(() => {
    const fillerDocumentHtml = getFillerDocumentHtml();
    Array.from(fillerDocumentHtml.querySelectorAll(".hovered")).forEach(
      (elem) => elem.classList.remove("hovered")
    );

    app.ports.fillerReceivedDocumentHeight.send(getFillerDocumentHeight());
  });
};

const blobToBase64 = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

const fillerUpdatePdfDocument = (_) => (base64PdfDocument) => {

  let data = atob(base64PdfDocument);

  var container = document.getElementById('filler-pdf-document');
  container.innerHTML = "";

  pdfjsLib.getDocument({ data: data }).promise.then((pdf) => {
    var currPage = 1;

    let handlePage = (page) => {
      //This gives us the page's dimensions at full scale
      var viewport = page.getViewport({ scale: 2 });

      //We'll create a canvas for each page to draw it on
      var canvas = container.appendChild(document.createElement("canvas"));
      canvas.classList.add('pdf-canvas');
      var context = canvas.getContext('2d');
      canvas.width = viewport.width;
      canvas.height = page.view[3] * 2;

      //Draw it on the canvas
      page.render({ canvasContext: context, viewport: viewport });

      currPage++;
      if (pdf !== null && currPage <= pdf.numPages) {
        pdf.getPage(currPage).then(handlePage);
      }
    }
    pdf.getPage(currPage).then(handlePage)
  }).catch((err) => console.log(err))
};

const builderDeleteCommentThread =
  (app) =>
    (commentThreadId) =>
      requestAnimationFrame(() =>
        getEditor((editor) => {
          const selector = `[data-teto-comment-thread-id="${commentThreadId}"]`;
          const [thread] = editor.dom.select(selector);
          if (thread) {
            thread.replaceWith(...thread.childNodes);
          }
        })
      );


const builderGetReferenceHtml =
  (app) =>
    ({ referenceGuid }) =>
      requestAnimationFrame(() =>
        getEditor((editor) => {
          const selector =
            '[data-teto-transient-box-outer="' +
            referenceGuid +
            '"]';
          const elem = editor.dom.select(selector);

          if (elem && elem[0]) {
            const wrapper = getWrapperElement(elem[0]);
            const referenceHtml = deriveReferenceHtml(wrapper);
            app.ports.builderGetReferenceHtmlReply.send({ referenceHtml });
          }
        })
      );

const builderDereferenceField =
  (app) =>
    ({ targetId, html, fieldGuidsExamplesToReRender }) =>
      requestAnimationFrame(() =>
        getEditor((editor) =>
          dereferenceField(app)(
            editor,
            targetId,
            html,
            fieldGuidsExamplesToReRender
          )
        )
      );

const builderUnmountEditor = (app) => () =>
  getEditor((editor) => {
    // Temporary fix for timing issue regarding teardown of Builder.
    // In bigger templates, this code is called after Elm navigates to the next page, and the editor element is not in DOM anymore.
    // This in turn causes a crashing error in Filler.
    if (document.body.contains(editor.targetElm)) {
      sendEditorContentsToElm(app)(editor, [], [], false);
    }

    // When removing, editor instance triggers Dirty event, which causes empty html to be sent to ELM, and this triggers
    // saving of an empty template
    editor.off("Dirty");
    editor.remove();
  });

const builderGetLatestHtml = (app) => () =>
  getEditor((editor) => {
    if (document.body.contains(editor.targetElm)) {
      sendEditorContentsToElm(app)(editor, [], [], true);
    }
  });

const builderReferenceField =
  (app) =>
    ({ html, targetId, fieldGuidsExamplesToReRender }) =>
      getEditor((editor) => {
        const newNode = parseHTML(html);
        let newNodeId = newNode.firstChild.id;

        if (newNodeId === "" || newNodeId === undefined) {
          // if the first child had no id,
          // try with the second child
          if (newNode.children[1]) {
            newNodeId = newNode.children[1].id;
          }
          // in cases when the user is referencing a table row, the annotated html
          // includes both the label row and the reference row. In that case, the new node id
          // is found in the second element/row.
        }

        if (targetId) {
          const node = editor.dom.select(`[id="${targetId}"]`)[0];
          editor.dom.replace(newNode, node);
          const newDOM = editor.dom.get(newNodeId);
          try {
            editor.selection.select(newDOM);
          } catch (err) {
            console.error("Impossible to select the new reference", err);
          }
        } else {
          const range = editor.selection.getRng();
          const isSelectingText = range.endOffset - range.startOffset !== 0;

          editor.selection.setNode(newNode);
          const newDOM = editor.dom.get(newNodeId);
          if (isSelectingText) {
            editor.selection.select(newDOM);
            removeLeftoverParagraphs(newDOM);
          } else {
            editor.selection.getSel().collapse(newDOM);
          }
        }
        requestAnimationFrame(() => {
          // scroll the editor to the new element using browser's native scrollIntoView
          const newDOM = editor.dom.get(newNodeId);
          newDOM.scrollIntoView({ behavior: "smooth", block: "center" });
        });

        sendEditorContentsToElm(app)(editor, [], fieldGuidsExamplesToReRender, false);
      });

const builderAddCommentElement =
  (app) =>
    ({ html, targetId }) =>
      getEditor((editor) => {
        const newNode = parseHTML(html);
        let newNodeId = newNode.firstChild.id;

        if (targetId) {
          const node = editor.dom.select(`[id="${targetId}"]`)[0];
          editor.dom.replace(newNode, node);
          const newDOM = editor.dom.get(newNodeId);
          try {
            editor.selection.select(newDOM);
          } catch (err) {
            console.error("Impossible to select the new reference", err);
          }
        } else {
          const range = editor.selection.getRng();
          const isSelectingText = range.endOffset - range.startOffset !== 0;

          editor.selection.setNode(newNode);

          //TODO : this must be fixed, this is just a temporary workaround
          editor.dom.select("[data-teto-comment-thread]")
            .forEach((comment) => {
              comment.addEventListener("click", function (e) {
                e.stopPropagation();
                if (editor.dom.select('body')[0].classList.contains("comments-visible")) {
                  app.ports.builderCommentThreadClicked.send(parseInt(comment.dataset.tetoCommentThreadId));
                }
              });
            });

          const newDOM = editor.dom.get(newNodeId);
          if (isSelectingText) {
            editor.selection.select(newDOM);
          }
        }

        sendEditorContentsToElm(app)(editor, [], [], false);
      });

const builderUpdateReferenceAttributes =
  (app) =>
    (referenceUpdates) => {
      getEditor((editor) => {
        referenceUpdates.forEach(({ oldFieldGuid, newFieldGuid, oldOptionGuid, newOptionGuid, nameReferenceType, property, nationalIdentityNumberReferenceType }) => {
          const selector = oldOptionGuid ? `[data-teto-field="${oldFieldGuid}"][data-teto-field-option="${oldOptionGuid}"]` : `[data-teto-field="${oldFieldGuid}"]:not([data-teto-field-option])`;

          const referenceNodes = editor.dom.select(
            selector
          );

          referenceNodes.forEach((el) => {
            el.setAttribute('data-teto-field', newFieldGuid);

            if (nameReferenceType) {
              el.setAttribute('data-teto-name-reference-type', nameReferenceType);
            } else {
              el.removeAttribute('data-teto-name-reference-type');
            }

            if (newOptionGuid) {
              el.setAttribute('data-teto-field-option', newOptionGuid);
            } else {
              el.removeAttribute('data-teto-field-option');
            }

            if (property) {
              el.setAttribute('data-teto-field-property', property);
            } else {
              el.removeAttribute('data-teto-field-property');
            }

            if (nationalIdentityNumberReferenceType) {
              el.setAttribute('data-teto-national-identity-number-reference-type', nationalIdentityNumberReferenceType);
            } else {
              el.removeAttribute('data-teto-national-identity-number-reference-type');
            }

          });
        });

        sendEditorContentsToElm(app)(editor, referenceUpdates.map(x => x.newFieldGuid), [], false);
      })
    }

// When selecting multiple paragraphs to make a reference, the range includes the text in first and last paragraph, doesn't include the paragraphs themselves.
// When the reference is made, selected contents are replaced with annotated html, but the empty paragraphs are left.
// Then, when the template is loaded again, tinymce transforms these empty paragraphs/adds a <br> tag inside them.
const removeLeftoverParagraphs = (referenceNode) => {
  const leftovers = Array.from(referenceNode.parentElement.children).filter(
    (el) =>
      el.matches("p:empty") &&
      (el.nextSibling === referenceNode || el.previousSibling === referenceNode)
  );
  leftovers.forEach((el) => el.remove());
};

const builderGetHeadings = (app) => () =>
  getEditor((editor) => {
    const headings = editor.dom
      .select("h1, h2, h3, h4, h5, h6")
      .map(addId)
      .map((elem) => ({
        id: elem.id,
        headingLevel: Number(elem.nodeName.substr(1)),
        content: elem.innerText,
      }));

    app.ports.builderReceiveHeadings.send(headings);
  });

const builderScrollToCommentThread = (id) =>
  getEditor((editor) => {
    const [elem] = editor.dom.select(
      `[data-teto-comment-thread-id="${id}"]`
    );

    if (elem) {
      scrollWithin(elem.closest("html"), elem);
    }
  });

const builderShowCommentThreads = () =>
  getEditor((editor) => {
    const [body] = editor.dom.select("body");

    if (body) {
      body.classList.add("comments-visible")
    }
  });

const builderHideCommentThreads = () =>
  getEditor((editor) => {
    const [body] = editor.dom.select("body");

    if (body) {
      body.classList.remove("comments-visible")
    }
  });


const builderMarkCommentThreadAsActive = (id) =>
  getEditor((editor) => {
    builderUnmarkCommentThreadsAsActive();

    const [elem] = editor.dom.select(
      `[data-teto-comment-thread-id="${id}"]`
    );

    if (elem) {
      elem.dataset.tetoCommentThreadActive = true;
    }
  });

const builderUnmarkCommentThreadsAsActive = () =>
  getEditor((editor) => {
    const elem = editor.dom.select(
      `[data-teto-comment-thread-active]`
    );

    elem.forEach(elem => {
      delete elem.dataset.tetoCommentThreadActive;
    });
  });

const builderScrollToHeading = (id) =>
  getEditor((editor) => {
    const elem = editor.dom.get(id);

    if (elem) {
      elem.scrollIntoView();
    }
  });

const builderScrollToReference = (guid) =>
  getEditor((editor) => {
    const [elem] = editor.dom.select(
      `[data-teto-field="${guid}"], [data-teto-field-option="${guid}"]`
    );

    if (elem) {
      scrollWithin(elem.closest("html"), elem);
    }
  });

const builderScrollToExactReference = (id) =>
  getEditor((editor) => {
    const elem = editor.dom.select(`[id="${id}"]`);

    if (elem && elem[0]) {
      editor.selection.select(elem[0]);
      scrollWithin(elem[0].closest("html"), elem[0]);
    }
  });

const copyTextToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

const copyHtmlToClipboard = (text) => {
  const type = "text/html";
  const blob = new Blob([text], { type });
  const data = [new ClipboardItem({ [type]: blob })];
  navigator.clipboard.write(data);
};

const copyDataToClipboard = (obj) => {
  const type = "text/plain";
  const blob = new Blob([JSON.stringify(obj)], { type });
  const data = [new ClipboardItem({ [type]: blob })];
  navigator.clipboard.write(data);
}

const fillerGetHeadings = (app) => () => {
  const fillerDocumentHtml = getFillerDocumentHtml();
  const headings = Array.from(
    fillerDocumentHtml.querySelectorAll(
      "h1, h2, h3, h4, h5, h6"
    )
  )
    .map(addId)
    .map((elem) => ({
      id: elem.id,
      headingLevel: Number(elem.nodeName.substr(1)),
      content: elem.innerText,
    }));

  app.ports.fillerReceiveHeadings.send(headings);
};

const fillerRenderHtml = (app) => (html) =>
  requestAnimationFrame(() => {
    const fillerDocumentHtml = getFillerDocumentHtml();

    if (!fillerDocumentHtml) {
      return console.error("Missing filler document!");
    }

    fillerDocumentHtml.open();
    fillerDocumentHtml.write(html);
    fillerDocumentHtml.close();

    fillerDocumentHtml.addEventListener('keydown', tetoShortcut(app));

    fillerDocumentHtml
      .querySelector("div[data-teto-root]")
      .classList.add("fillerDocumentWithHiddenChildren");

    // Add click listeners to values.
    Array.from(
      fillerDocumentHtml.querySelectorAll("[data-teto-field]")
    ).forEach((reference) => {
      const onReferenceClick = (e) => {
        e.stopPropagation();
        return app.ports.fillerScrollToThenFocusOnField.send(
          createElementReferenceInfo(reference)
        );
      };

      const onReferenceMouseEnter = (e) => {
        e.stopPropagation();
        return app.ports.fillerMouseEnterReference.send(
          createElementReferenceInfo(reference)
        );
      };

      const onReferenceMouseLeave = (e) => {
        e.stopPropagation();

        const relatedReferenceTarget = findRelatedReferenceTarget(
          e.relatedTarget
        );

        if (
          relatedReferenceTarget &&
          relatedReferenceTarget.dataset &&
          relatedReferenceTarget.dataset.tetoField
        ) {
          return app.ports.fillerMouseEnterReference.send(
            createElementReferenceInfo(relatedReferenceTarget)
          );
        } else {
          return app.ports.fillerMouseLeaveReference.send(
            createElementReferenceInfo(reference)
          );
        }
      };

      reference.addEventListener("click", onReferenceClick);
      reference.addEventListener("mouseenter", onReferenceMouseEnter);
      reference.addEventListener("mouseleave", onReferenceMouseLeave);
    });

    app.ports.fillerRenderHtmlReply.send(null);
  });

const fillerRenderEditedHtml = (app) => (html) =>
  requestAnimationFrame(() => {
    const fillerDocumentHtml = getFillerDocumentHtml();

    if (!fillerDocumentHtml) {
      return console.error("Missing filler document!");
    }

    fillerDocumentHtml.open();
    fillerDocumentHtml.write(html);
    fillerDocumentHtml.close();

    fillerDocumentHtml.querySelector("div[data-teto-root]");

    setTimeout(() => app.ports.fillerRenderEditedHtmlReply.send(null), 500);
  });

const fillerGetLatestHtml = (app) => () =>
  getEditor((editor) => {
    if (document.body.contains(editor.targetElm)) {
      app.ports.fillerUpdateHtml.send({ html: editor.getContent(), isUpdateBeforeSaving: true });
    }
  });

const createElementReferenceInfo = (el) => ({
  fieldGuid: el.dataset.tetoField,
  maybeListIndex:
    el.dataset.tetoListIndex != null
      ? parseInt(el.dataset.tetoListIndex, 10)
      : null,
  maybeProperty: el.dataset.tetoFieldProperty || null,
});

const findRelatedReferenceTarget = (el) => {
  if (el) {
    if (el.dataset && el.dataset.tetoField) {
      return el;
    } else {
      return findRelatedReferenceTarget(el.parentElement);
    }
  }
};

const scrollToId = ({ id, shouldFocus }) =>
  requestAnimationFrame(() => {
    const elem = document.getElementById(id);

    if (elem) {
      zenScrollTo(elem).then(() => (shouldFocus ? elem.focus() : null));
    }
  });

const fillerScrollWithinSelectionBox = ({ selectionId, selectionBoxId }) => {
  const selection = document.querySelector(
    `#${selectionBoxId} #${selectionId}`
  );

  if (!selection) return console.warn("Invalid selection.");

  mutate(
    document.getElementById(selectionBoxId),
    "scrollTop",
    selection.offsetTop - 20
  );
};

const usageBufferGet = (app) => () =>
  app.ports.usageBufferGetReply.send(localStorage.getItem("usage"));

const usageBufferSave = (value) => {
  const valueAsJson = value === null ? null : JSON.stringify(value);
  localStorage.setItem("usage", valueAsJson);
};

const usageBufferClear = () => localStorage.clear();

const getDebugInfo = (app) => () =>
  app.ports.getDebugInfoReply.send({
    operatingSystem: navigator.platform,
    userAgent: navigator.userAgent,
    width: window.innerWidth,
    height: window.innerHeight,
    language: navigator.language,
  });

const selectNameEditorText = () => {
  const nameEditor = document.getElementById("name-editor");

  if (nameEditor) {
    nameEditor.setSelectionRange(0, nameEditor.value.length);
  }
};

const openInNewTab = (url) => {
  window.open(url, "_blank");
};

const setElementMaxHeightToScrollHeight = ([transitionDirection, id]) => {
  const el = document.getElementById(id);

  if (el) {
    let maxHeightStart, maxHeightEnd;

    if (transitionDirection === "openingTransition") {
      maxHeightStart = "0";
      maxHeightEnd = `${el.scrollHeight}px`;
      // set overflow hidden
      el.style.overflow = "visible";
    } else if (transitionDirection === "closingTransition") {
      maxHeightStart = `${el.scrollHeight}px`;
      maxHeightEnd = "0";
      // set overflow hidden
      el.style.overflow = "hidden";
    }

    // if the card field is expanding, reset the max height after the transition,
    // to allow inner elements to expand
    if (maxHeightStart === "0") {
      el.ontransitionend = () => {
        el.style.maxHeight = "none";
        // remove the transitionend listener
        el.ontransitionend = null;
      };
    }

    // transition max height
    el.style.maxHeight = maxHeightStart;
    requestAnimationFrame(() => {
      el.style.maxHeight = maxHeightEnd;
    });
  } else {
    console.warn(`Element with id ${id} not found.`);
  }
};


const addEasyMDEBuilder = (app) => (config) => {
  let easymde = new EasyMDE(
    {
      element: document.getElementById(config.id)
      , toolbar: [
        "bold", "italic", "heading",
        "|", "unordered-list", "ordered-list", "link", "table", "quote",
        "|", "undo", "redo",
        "|", "preview", "side-by-side", "guide"]
      , initialValue: config.input
      , maxHeight: "600px"
      , autofocus: true
      , sideBySideFullscreen: false
      , spellChecker: false
    });

  easymde.toggleSideBySide();

  easymde.codemirror.on("change", function () {
    app.ports.updateMarkdownEditorValue.send(easymde.value());
  });

  app.ports.updateMarkdownEditorValue.send(easymde.value());
};


//
// main function to apply all ports to the app
//
export const setupPorts = (app) => {
  const { ports } = app;

  ports.getOAuthGoogle.subscribe(getOAuthGoogle(app));
  ports.storeAndGetOAuthGoogle.subscribe(storeAndGetOAuthGoogle(app));
  ports.getClipboardData.subscribe(getClipboardData(app));
  ports.initServerSentEvents.subscribe(initServerSentEvents(app));
  ports.builderAddCommentElement.subscribe(builderAddCommentElement(app));
  ports.builderDeleteCommentThread.subscribe(builderDeleteCommentThread(app));
  ports.builderGetReferenceHtml.subscribe(builderGetReferenceHtml(app));
  ports.builderDereferenceField.subscribe(builderDereferenceField(app));
  ports.builderMountEditor.subscribe(builderMountEditor(app));
  ports.builderToggleLabels.subscribe(builderToggleLabels);
  ports.builderToggleLabelsStyle.subscribe(builderToggleLabelsStyle);
  ports.builderToggleLabelsLayout.subscribe(builderToggleLabelsLayout);
  ports.builderHtmlChange.subscribe(builderHtmlChange);
  ports.builderUpdateReferenceLabelAndName.subscribe(builderUpdateReferenceLabelAndName(app));
  ports.builderUpdateVersion.subscribe(builderUpdateVersion);
  ports.builderGetLatestHtml.subscribe(builderGetLatestHtml(app));
  ports.fillerMountEditor.subscribe(fillerMountEditor(app));
  ports.fillerUnmountEditor.subscribe(fillerUnmountEditor(app));
  ports.fillerGetDocumentHeight.subscribe(fillerGetDocumentHeight(app));
  ports.fillerRenderValues.subscribe(fillerRenderValues(app));
  ports.fillerHighlightReferences.subscribe(fillerHighlightReferences(app));
  ports.fillerUpdatePdfDocument.subscribe(fillerUpdatePdfDocument(app))

  ports.fillerHighlightHoveredReferences.subscribe(
    fillerHighlightHoveredReferences(app)
  );

  ports.fillerRemoveHighlightHoveredReferences.subscribe(
    fillerRemoveHighlightHoveredReferences(app)
  );

  ports.builderUnmountEditor.subscribe(builderUnmountEditor(app));
  ports.builderReferenceField.subscribe(builderReferenceField(app));
  ports.builderUpdateReferenceAttributes.subscribe(builderUpdateReferenceAttributes(app));
  ports.builderFocusOnEditor.subscribe(builderFocusOnEditor(app));
  ports.builderGetHeadings.subscribe(builderGetHeadings(app));
  ports.builderShowCommentThreads.subscribe(builderShowCommentThreads)
  ports.builderHideCommentThreads.subscribe(builderHideCommentThreads)
  ports.builderScrollToCommentThread.subscribe(builderScrollToCommentThread);
  ports.builderMarkCommentThreadAsActive.subscribe(builderMarkCommentThreadAsActive);
  ports.builderUnmarkCommentThreadsAsActive.subscribe(builderUnmarkCommentThreadsAsActive);
  ports.builderScrollToHeading.subscribe(builderScrollToHeading);
  ports.builderScrollToReference.subscribe(builderScrollToReference);
  ports.builderScrollToExactReference.subscribe(builderScrollToExactReference);
  ports.copyTextToClipboard.subscribe(copyTextToClipboard);
  ports.copyHtmlToClipboard.subscribe(copyHtmlToClipboard);
  ports.copyDataToClipboard.subscribe(copyDataToClipboard);
  ports.fillerGetHeadings.subscribe(fillerGetHeadings(app));
  ports.fillerRenderHtml.subscribe(fillerRenderHtml(app));
  ports.fillerRenderEditedHtml.subscribe(fillerRenderEditedHtml(app));
  ports.fillerGetLatestHtml.subscribe(fillerGetLatestHtml(app));
  ports.scrollToId.subscribe(scrollToId);
  ports.fillerScrollWithinSelectionBox.subscribe(
    fillerScrollWithinSelectionBox
  );
  ports.usageBufferGet.subscribe(usageBufferGet(app));
  ports.usageBufferSave.subscribe(usageBufferSave);
  ports.usageBufferClear.subscribe(usageBufferClear);
  ports.getDebugInfo.subscribe(getDebugInfo(app));
  ports.selectNameEditorText.subscribe(selectNameEditorText);
  ports.openInNewTab.subscribe(openInNewTab);
  ports.setElementMaxHeightToScrollHeight.subscribe(
    setElementMaxHeightToScrollHeight
  );

  ports.addEasyMDEBuilder.subscribe(addEasyMDEBuilder(app));

  ports.log.subscribe(console.log);
  ports.logError.subscribe(console.error);
  ports.logWarning.subscribe(console.warn);
};
