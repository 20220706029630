import { whiteSpaceAnnotationClear } from "./whiteSpacePlugin";

const zen = require("zenscroll");
// do not remove or icons in tinymce editor won't appear
// eslint-disable-next-line no-unused-vars
const icons = require("tinymce/icons/default");

export const OAuthGoogleStorageKey = "oAuthGoogle";

/* Helper function to parse html fragments, without adding extra stuff,
even when that fragment is a `<td>`.

This allows us to do precise replacements of nodes inside anywhere.
Sample code to demonstrate issues:
    var fragments = ["<li>item</li>","<td>cell</td>","<td>cell1</td><td>cell2</td>"]
    var methods = [
        ["parseFromString",(html) => new DOMParser().parseFromString(html, "text/html").body.firstChild],
        ["createContextualFragment",(html) => document.createRange().createContextualFragment(html)],
        ["createElement+cloneNode",(html) => {
            var t = document.createElement("template");
            t.innerHTML = html;
            return t.content.cloneNode(true);
        }]
    ]
    fragments.forEach(fragment => {
        methods.forEach(method => {
            console.log(method[0], fragment, method[1](fragment));
        });
    });
*/
export const parseHTML = (html) => {
  const t = document.createElement("template");
  mutate(t, "innerHTML", html);
  // Notice that t.content is a DocumentFragment, that does not handle click events.
  // cloneNode is requried to transform it into a regular HTML Node.
  return t.content.cloneNode(true);
};

export const getEditor = (fn) =>
  tinymce.activeEditor
    ? fn(tinymce.activeEditor)
    : console.error("editor unavailable");

export const tetoShortcut = (app) => (e) => {
  // Notice that on Mac the ctrl key is placed quite far off, and the cmd key is better suited,
  // so accept either of ctrl or cmd.
  // All recent browsers encode cmd as metaKey, note however that
  // the windows key also encodes as metaKey.
  if (e.code === "KeyK" && (e.ctrlKey || e.metaKey) && e.shiftKey) {
    e.preventDefault();
    e.stopPropagation();

    return app.ports.referenceFieldShortcut.send(null);
  } else if (e.code === "KeyK" && (e.ctrlKey || e.metaKey)) {
    e.preventDefault();
    e.stopPropagation();

    return app.ports.actionPaletteToggle.send(null);
  } else if (e.code === "KeyP" && (e.ctrlKey || e.metaKey)) {
    e.preventDefault();
    e.stopPropagation();

    return app.ports.printKeyboardShortcut.send(null);
  } else if (e.code === "KeyC" && (e.ctrlKey || e.metaKey) && e.shiftKey) {
    e.preventDefault();
    e.stopPropagation();

    return app.ports.builderCopySelectionWithoutReferencesShortcut.send(null);
  } else if (e.code === "KeyS" && (e.ctrlKey || e.metaKey)) {
    e.preventDefault();
    e.stopPropagation();

    return app.ports.saveShortcut.send(null);
  } else if (e.code === "KeyO" && (e.ctrlKey || e.metaKey)) {
    e.preventDefault();
    e.stopPropagation();

    return app.ports.openActionMenuShortcut.send(null);
  } else if (e.code === "KeyL" && (e.ctrlKey || e.metaKey) && e.shiftKey) {
    e.preventDefault();
    e.stopPropagation();

    return app.ports.toggleReferenceListShortcut.send(null);
  } else if (e.code === "KeyI" && (e.ctrlKey || e.metaKey) && e.shiftKey) {
    e.preventDefault();
    e.stopPropagation();

    return app.ports.toggleInstructionsShortcut.send(null);
  } else if (e.code === "KeyF" && (e.ctrlKey || e.metaKey) && e.shiftKey) {
    e.preventDefault();
    e.stopPropagation();

    return app.ports.toggleFieldsModeShortcut.send(null);
  }
};

export const dereferenceField =
  (app) => (editor, targetId, html, fieldGuidsExamplesToReRender) => {
    /*
        // Outline of the logic behind the bookmark handling that follows:

        1) Bookmark of the field selected (this bookmark is not used):
    ```
    {
    start: "div[0]/div[3]/span[0],before",
    end: "div[0]/div[3]/span[0],after"
    }
    ```
        This Dom will be removed so these tokens will be invalid.

        2) Bookmark when it is collapsed (placing caret at the start of the selection):
    ```
    {
    start: "div[0]/div[3]/text()[0],16",
    end: "div[0]/div[3]/text()[0],16"
    }
    ```

        The start will be helpful, but the end token isn't, since the end is adjust after insertion of new content.
        Notice the after the insertion of new content the caret is positioned at the end of the new content.

        3) Bookmark after content is replaced
    ```
    {
    start: "div[0]/div[3]/text()[1],1",
    end: "div[0]/div[3]/text()[1],1"
    }
    ```

        The caret is moved at the end of the new content when content is inserted, so now we have the End position.

        So the final selection that we need is:

    ```
    {
    start: "div[0]/div[3]/text()[0],16",
    end: "div[0]/div[3]/text()[1],1"
    }
    ```
      */

    const dom = editor.dom.get(targetId);
    const root = editor.dom.getRoot();
    editor.selection.select(dom);

    // if it was a TR reference, we need to manually remove the label container
    if (dom.tagName == "TR") {
      root
        .querySelector(
          "[data-teto-transient-label-container='" + targetId + "'",
        )
        .remove();
    }

    // Since the Dom will be replaced then collapse in order to get the StarBookmark
    // in the parent element
    editor.selection.collapse(true);
    const startBookmark = editor.selection.getBookmark(3);

    // Replace the Dom with new content
    const newNode = parseHTML(html);
    editor.dom.replace(newNode, dom);
    editor.selection.select(editor.dom.select(`[id="${targetId}"]`)[0]);

    // Get the EndBookmark with the new content inserted
    const endBookmark = editor.selection.getBookmark(3);

    // Move to new selection Bookmark
    const selectionBookmark = {
      start: startBookmark.start,
      end: endBookmark.end,
    };

    try {
      editor.selection.moveToBookmark(selectionBookmark);
    } catch (err) {
      console.error(err);
      app.ports.reportFrontendError.send({
        module_: "builder",
        message: "editor.selection.moveToBookmark",
      });
    }

    app.ports.builderUpdateSelection.send({
      selectionHtml: "",
      referenceHtml: null,
      parentHtml: "",
    });

    sendEditorContentsToElm(app)(
      editor,
      fieldGuidsExamplesToReRender,
      [],
      false,
    );

    editor.focus();
  };

export const sendEditorContentsToElm =
  (app) =>
  (
    editor,
    fieldGuidsLabelsToReRender,
    fieldGuidsExamplesToReRender,
    isUpdateBeforeSaving,
  ) => {
    const editorBody = editor.getBody();

    // clean the body up from any potential white-space annotations
    // ( we make a clone of the body to avoid mutating the DOM )
    const editorBodyClone = editorBody.cloneNode(true);
    const editorBodyCloneWhiteSpaceAnnotationCleared =
      whiteSpaceAnnotationClear(editorBodyClone);
    const content = editorBodyCloneWhiteSpaceAnnotationCleared.innerHTML;

    const htmlReferenceTree = createHtmlReferenceTree(editor);

    app.ports.builderUpdateHtml.send({
      html: content,
      htmlReferenceTreeJson: htmlReferenceTree,
      fieldGuidsExamplesToReRender: fieldGuidsExamplesToReRender,
      fieldGuidsLabelsToReRender: fieldGuidsLabelsToReRender,
      isUpdateBeforeSaving,
    });
  };

export const createHtmlReferenceTree = (editor) => {
  const root = editor.dom.getRoot();

  const buildTeToHtmlAttributes = (node) => {
    const id = node.getAttribute("id");
    const template = node.getAttribute("data-teto-template");
    const field = node.getAttribute("data-teto-field");
    const option = node.getAttribute("data-teto-field-option");
    const property = node.getAttribute("data-teto-field-property");
    const action = node.getAttribute("data-teto-action");
    const listFilter = node.getAttribute("data-teto-list-filter");
    const referenceName = node.getAttribute("data-teto-reference-name");
    const listOfSignatures = node.getAttribute("data-teto-list-of-signatures");
    const sentence = node.getAttribute("data-teto-sentence");
    const numberOfElements = node.getAttribute("data-teto-number-of-elements");
    const expectedNumberOfListElements = node.getAttribute(
      "data-teto-expected-number-of-list-elements",
    );
    const sequenceNumberLevel = node.getAttribute(
      "data-teto-sequence-number-level",
    );
    const sequenceNumberCrossReference = node.getAttribute(
      "data-teto-sequence-number-cross-reference",
    );
    const dateReferenceType = node.getAttribute(
      "data-teto-date-reference-type",
    );
    const nationalIdentityNumberReferenceType = node.getAttribute(
      "data-teto-national-identity-number-reference-type",
    );
    const nameReferenceType = node.getAttribute(
      "data-teto-name-reference-type",
    );

    const children = Array.from(node.children)
      .filter((node) => !node.classList.contains("mce-offscreen-selection"))
      .flatMap(buildTeToHtmlAttributes);

    if (
      id != undefined &&
      field != undefined &&
      template != undefined &&
      action != undefined
    ) {
      return [
        {
          id,
          template,
          field,
          option,
          property,
          listFilter,
          listOfSignatures,
          referenceName,
          sentence,
          numberOfElements,
          sequenceNumberLevel,
          sequenceNumberCrossReference,
          dateReferenceType,
          nationalIdentityNumberReferenceType,
          nameReferenceType,
          expectedNumberOfListElements,
          action,
          children,
        },
      ];
    } else {
      return children;
    }
  };

  return buildTeToHtmlAttributes(root);
};

export const mutate = (object, field, value) => {
  // eslint-disable-next-line fp/no-mutation
  object[field] = value;
  return object;
};

export const addTransientId = (el) => {
  if (!el.id) {
    mutate(
      el,
      "id",
      "transient" + window.crypto.getRandomValues(new Uint32Array(2)).join(""),
    );
  }
  return el;
};

// HTMLElement -> HTMLElement
export const addId = (el) => {
  if (!el.id) {
    mutate(
      el,
      "id",
      window.crypto.getRandomValues(new Uint32Array(2)).join(""),
    );
  }
  return el;
};

const getScrollableParent = (el) => {
  // When there is nesting of multiple divs, inside a scollable parent,
  // then the outermost div containing the target element must be selected as target
  // for zenScroll to calculate the offset correctly, since zenScroll just looks
  // at the parent offset of the element, and not the offset between the returned
  // {target:.., and parent} elemement.
  /* So for the following html structure
    <div scrollbarY=true>
      <div id=A>first box</div>
      <div id=B>second box</div>
      <div id=C>third box border<div id=C1>third box intermediate<input id=C11>focusable element</div></div></div>
    </div>

    zenScrool needs to have `<div id=C>` as target inside parent `<div scrollbarY=true>`.
    */
  const parent = el.parentNode;
  return parent && parent !== window.document
    ? getComputedStyle(parent).overflowY === "auto" ||
      parent.nodeName === "BODY" // TODO: Might need to support other values.
      ? { target: el, parent }
      : getScrollableParent(parent)
    : null;
};

export const scrollWithin = (parent, target) => {
  const duration = 500; // ms
  const edgeOffset = 30; // px
  return new Promise((resolve) => {
    return zen
      .createScroller(parent, duration, edgeOffset)
      .center(target, undefined, undefined, resolve);
  });
};

export const zenScrollTo = (el) => {
  const res = getScrollableParent(el);
  if (res) {
    const { target, parent } = res;
    return scrollWithin(parent, target);
  } else {
    return Promise.reject(Error("Parent not found"));
  }
};

export const isHidden = (el) =>
  // Determines if the element or any of its
  // parents are hidden.
  // https://stackoverflow.com/a/21696585/4224679
  el.offsetParent === null;

export const isEmptyString = (str) =>
  // CharCode check to handle TinyMCE
  // inserting a "Zero width non-breaking space".
  // https://support.tiny.cloud/hc/en-us/articles/226359007-Character-Encoding-in-TinyMCE
  str.length === 0 || str === String.fromCharCode(65279);

export const fillerValueReferenceRenderInstructions = {
  MARK_AS_INVALID: (_) => (elem) => {
    elem.classList.remove("valid");
    elem.classList.add("invalid");
  },
  MARK_AS_VALID: (_) => (elem) => {
    elem.classList.remove("invalid");
    elem.classList.add("valid");
  },
  HIDE: (_) => (elem) => mutate(elem.style, "display", "none"),
  SHOW: (display) => (elem) => mutate(elem.style, "display", display),
  REPLACE: (txt) => (elem) => mutate(elem, "innerHTML", txt),
  ADJUST_SENTENCE: (_) => (elem) => {
    // find parent sentence wrapper
    let parent = elem.closest("[data-teto-sentence-wrapper]");
    if (parent) {
      let commas = parent.querySelectorAll("[data-teto-sentence-comma]");
      commas.forEach((comma) => {
        parent.removeChild(comma);
      });

      let visibleChildren = Array.from(parent.children)
        .filter((elem) => elem.dataset.tetoSentenceConjunction !== "true")
        .filter((elem) => elem.style.display !== "none");

      let conjunction = parent.querySelector(
        "[data-teto-sentence-conjunction]",
      );

      if (conjunction) {
        if (visibleChildren.length == 0 || visibleChildren.length == 1) {
          conjunction.style.display = "none";
        } else {
          conjunction.style.display = "unset";
        }
      }

      visibleChildren.forEach((child, index) => {
        if (index < visibleChildren.length - 2) {
          let comma = document.createElement("span");
          comma.setAttribute("data-teto-sentence-comma", "true");
          comma.innerHTML = ", ";
          child.after(comma);
        } else if (index == visibleChildren.length - 1) {
          // move conjunction
          if (conjunction) {
            child.before(conjunction);
          }
        }
      });
    }
  },
  ADJUST_SIGNATURE_LIST: (_) => (elem) => {
    reArrangeSignatureTables(elem);
  },
};

export const getFillerDocumentHtml = () => {
  const iframe = document.getElementById("filler-document");
  // Ref. https://stackoverflow.com/a/11107977/1023558
  const iframeDocument = iframe
    ? iframe.contentDocument || iframe.contentWindow.document
    : null;
  return iframeDocument;
};

export const getFillerDocumentHeight = () => {
  const documentHtml = getFillerDocumentHtml();

  const height = documentHtml
    .querySelectorAll("[data-teto-root='true']")[0]
    .getBoundingClientRect().height;

  return height;
};

export const isElementVisible = (el) => el.offsetParent != null;

// TODO: This is a temporary fix, since our current data flow in filler does not cater for
// changing of the html structure when updating field values.
// Since we have to use an html table to show a signature field, if the signature list is
// filtered, whenever a cell is hidden, we need to rearrange the table properly to always have
// max 3 cells in a row.
export const reArrangeSignatureTables = (elem) => {
  const tableEl = elem.closest("[data-teto-signature-table] tbody");

  if (tableEl != null) {
    const signatureCells = Array.from(
      tableEl.querySelectorAll("td[data-teto-signature-cell]"),
    );

    const groupedByThreeVisibleCells = signatureCells.reduce(
      (acc, currentCell) => {
        if (!isElementVisible(currentCell)) {
          return {
            currentRow: [...acc.currentRow, currentCell],
            rows: acc.rows,
          };
        } else {
          if (acc.currentRow.filter(isElementVisible).length == 3) {
            return {
              currentRow: [currentCell],
              rows: [...acc.rows, acc.currentRow],
            };
          } else {
            return {
              currentRow: [...acc.currentRow, currentCell],
              rows: acc.rows,
            };
          }
        }
      },
      { currentRow: [], rows: [] },
    );

    let tableRows = [
      ...groupedByThreeVisibleCells.rows,
      groupedByThreeVisibleCells.currentRow,
    ];

    tableRows = tableRows.map((row) => {
      const tr = document.createElement("tr");

      const numberOfVisible = row.filter(isElementVisible).length;

      if (numberOfVisible == 3) {
        row.forEach((td) => tr.appendChild(td));
      } else if (numberOfVisible == 1) {
        tr.appendChild(document.createElement("td"));
        row.forEach((td) => tr.appendChild(td));
        tr.appendChild(document.createElement("td"));
      } else if (numberOfVisible == 2) {
        row.forEach((td) => {
          if (isElementVisible(td)) {
            tr.appendChild(td);
            tr.appendChild(document.createElement("td"));
          } else {
            tr.appendChild(td);
          }
        });
      } else {
        row.forEach((td) => {
          tr.appendChild(td);
        });
      }

      return tr;
    });

    // eslint-disable-next-line fp/no-loops
    while (tableEl.firstChild) {
      tableEl.removeChild(tableEl.firstChild);
    }

    tableRows.forEach((tr) => tableEl.appendChild(tr));
  }
};

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export function getTextWidth(text, font) {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ||
    // eslint-disable-next-line fp/no-mutation
    (getTextWidth.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  // eslint-disable-next-line fp/no-mutation
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

// Super simple locale detection - that will work in many cases.
// Ref. https://stackoverflow.com/a/38150585/1023558
export const locale =
  (navigator.languages && navigator.languages[0]) || // Chrome / Firefox
  navigator.language || // All browsers
  navigator.userLanguage; // IE <= 10

export const getParents = function (elem, selector) {
  // Set up a parent array
  var parents = [];
  let parentElem = elem.parentElement;

  // Push each parent element to the array
  while (parentElem) {
    if (selector) {
      if (parentElem.matches(selector)) {
        parents.push(parentElem);
      }
      parentElem = parentElem.parentElement;
      continue;
    }
    parents.push(parentElem);

    parentElem = parentElem.parentElement;
  }

  // Return our parent array
  return parents;
};
