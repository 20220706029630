const debounce = require("lodash.debounce");
import {
  addTransientId,
  tetoShortcut,
  isEmptyString,
  sendEditorContentsToElm,
  mutate,
  scrollWithin,
  getEditor,
} from "./helpers";

const cleanAndApplyFormat =
  (styleFormats) =>
    ({ elements, classToApply = "" }) =>
      elements.forEach((cell) => {
        styleFormats.forEach((styleFormat) => {
          cell.classList.remove(styleFormat.format);
        });
        if (classToApply !== "") {
          cell.classList.add(classToApply);
        }
      });

const removeEmptyHeadingElements = (element) => {
  const headingElements = element.querySelectorAll("h1,h2,h3,h4,h5,h6");
  headingElements.forEach((headingElement) => {
    if (headingElement.textContent === "") {
      headingElement.outerHTML = "";
    }
  });
};

const removeHeadingElementWrapper = (cell) => {
  // if the cell has an hx class, remove the <hx> tag inside it
  const hx = cell.querySelector("h1,h2,h3,h4,h5,h6");
  if (hx !== null) {
    hx.outerHTML = hx.innerHTML;
  }
};

const setDefaultStyleFormatOnPaste = ({ selection, styleFormats }) => {
  selection.childNodes.forEach((element) => {
    if (
      element.tagName === "P" &&
      element.classList &&
      !styleFormats.some((sf) => element.classList.contains(sf.format))
    ) {
      element.classList.add("Normal");
    }
  });
};

const handleDirty = ({ editor, app }) =>
  debounce(() => {
    // TODO: Avoid sending the contents if there are no relevant changes.
    editor.setDirty(false);
    // When testing, for large templates ELM uses around 300ms to process receiving html contents
    sendEditorContentsToElm(app)(editor, [], [], false);
  }, 500);

const handleFocus = (app) => (_) => {
  app.ports.builderEditorReceivedFocus.send(null);
};

const handlePastePostProcess =
  ({ app, editor, styleFormats }) =>
    ({ node /* ,mode, source, wordContent */ }) => {
      const referenceNodes = node.querySelectorAll("[data-teto-field]");

      referenceNodes.forEach((node_) => {
        let oldId = node_.getAttribute('id');
        let fieldGuid = node_.getAttribute('data-teto-field');
        let newId = window.crypto.getRandomValues(new Uint32Array(2)).join("");
        // eslint-disable-next-line fp/no-mutation
        node_.id = newId;
        node_.setAttribute('data-teto-transient-box-outer', newId);
        let label = node_.querySelector(':scope > [data-teto-transient-box-flex] > [data-teto-transient-box-label]');
        if (label) {
          label.setAttribute('data-teto-transient-box-label', newId);
        }
        let innerBox = node_.querySelector(':scope > [data-teto-transient-box-flex] > [data-teto-transient-box-border] > [data-teto-transient-box-inner]');
        if (innerBox) {
          innerBox.setAttribute('data-teto-transient-box-inner', newId);
        }

        let shouldUpdateCrossReferences = editor.dom.getRoot().querySelector(`[id="${oldId}"][data-teto-field="${fieldGuid}"]`) == null;
        if (shouldUpdateCrossReferences) {
          let sequenceNumberCrossReferences = editor.dom.getRoot().querySelectorAll(`[data-teto-field="${fieldGuid}"][data-teto-sequence-number-cross-reference="${oldId}"]`);
          sequenceNumberCrossReferences.forEach((crossRef) => {
            crossRef.setAttribute('data-teto-sequence-number-cross-reference', newId);
          })
        }

      });
      setDefaultStyleFormatOnPaste({ selection: node, styleFormats });
      // Adjust reference counts after paste
      sendEditorContentsToElm(app)(editor, [], [], false);
    };

const handleNodeChange =
  ({ editor, styleFormats }) =>
    (event) => {
      const outerBoxWhenEmpty =
        event.element.dataset &&
          event.element.dataset.tetoTransientBoxInner &&
          isEmptyString(event.element.innerText)
          ? event.element.closest("[data-teto-transient-box-outer]")
          : null;

      const commentWhenEmpty =
        event.element.dataset &&
          event.element.dataset.tetoCommentThreadId &&
          isEmptyString(event.element.innerText)
          ? event.element
          : null;

      removeEmptyReferenceOrComment(editor, outerBoxWhenEmpty);
      removeEmptyReferenceOrComment(editor, commentWhenEmpty);

      const matchStyleFormat = (index = 0) => {
        if (index < styleFormats.length) {
          if (editor.formatter.match(styleFormats[index].format)) {
            return true;
          } else {
            return matchStyleFormat(index + 1);
          }
        } else {
          return false;
        }
      };

      if (editor.selection.getNode().tagName === "P" && !matchStyleFormat()) {
        editor.selection.getNode().classList.add("Normal");
      }
    };

const removeEmptyReferenceOrComment = (editor, element) => {
  if (element) {
    // TinyMCE behaves weirdly when collapsing the selection to the beginning
    // (creates a giant caret)
    editor.selection.setNode(element.parentElement);

    if (element.previousSibling) {
      editor.selection.select(element.previousSibling);
      editor.selection.collapse();
    } else if (element.nextSibling) {
      editor.selection.select(element.nextSibling);
      editor.selection.collapse(true);
    }

    element.parentElement.removeChild(element);
  }
}

export const deriveReferenceHtml = (el) => {
  if (el) {
    if (el.tagName === "TR") {
      // in case when the field link is a whole table row,
      // we need to send in the label as well, which is the previous row
      return el.previousSibling.outerHTML + el.outerHTML;
    } else {
      return el.outerHTML;
    }
  }
};

export const getWrapperElement = (el) =>
  el.dataset && el.dataset.dataTetoTransientBoxOuter
    ? el
    : el.closest("[data-teto-transient-box-outer]");

const handleSelectionChange = ({ app, editor }) =>
  debounce((_) => {
    const isTransientLabelElement = (el) =>
      el.dataset && el.dataset.tetoTransientBoxLabel;

    const selectOuterBox = (el) => {
      const root = editor.dom.getRoot();
      const selector =
        '[data-teto-transient-box-outer="' +
        el.dataset.tetoTransientBoxLabel +
        '"]';
      const elementToSelect = root.querySelector(selector);

      return editor.selection.select(elementToSelect);
    };

    const getSelectionContent = () => {
      try {
        return editor.selection.getContent();
      } catch (e) {
        console.error(e);
        app.ports.reportFrontendError.send({
          module_: "builder",
          message: "editor.selection.getContent",
        });
        return "";
      }
    };

    const addTransientIdToChildren = (el) => {
      // Ensure that there is an id to connect to the proper node
      // after adding a reference.
      // Since we look upwards for the parent to check for selection of
      // all li contents, make sure the parent also get's an id.
      if (el) {
        el.querySelectorAll("ul,ol,li,tr,td").forEach(addTransientId);
      }
    };

    const deriveParentHtml = (el) => {
      if (
        // if the context element is a UL/OL/LI, return the outerHtml
        // because it will be used to compare contents inside ELM
        // and derive selection properly
        el.tagName === "OL" ||
        el.tagName === "UL" ||
        el.tagName === "LI"
      ) {
        addTransientIdToChildren(el);
        return el.outerHTML;
      } else if (
        // if the contextElement is a fieldLink (meaning that the user selected
        // a field reference), check if the parent node is a list element
        // in order to compare elements inside ELM and derive selection properly
        el.dataset.tetoTransientBoxOuter &&
        el.parentNode.tagName === "LI"
      ) {
        return el.parentNode.outerHTML;
      } else if (
        (el !== null && el.tagName === "TD") ||
        el.parentElement.tagName == "TD"
      ) {
        const parentTable = el.closest("table");
        addTransientIdToChildren(parentTable);
        return parentTable.outerHTML;
      } else {
        return "";
      }
    };

    const setEditorBookmark = () => {
      try {
        app.ports.builderSaveEditorBookmark.send(
          // Notice the usage of `3` that makes a bookmark from start offset to end offset (`getCaretBookmark`).
          // by using start and end offset, it doesn't matter that the html in between
          // changes, and therefore the bookmark is stable and covers the same logical
          // region regardless of the html transformation that is done when a field
          // is (un)referenced.
          // Ref. https://github.com/tinymce/tinymce/blob/79fc920232e76cadbe410ae51b9a6cae4a57c8b3/src/core/main/ts/bookmark/GetBookmark.ts#L228
          // and https://github.com/tinymce/tinymce/blob/79fc920232e76cadbe410ae51b9a6cae4a57c8b3/src/core/main/ts/bookmark/GetBookmark.ts#L172
          editor.selection.getBookmark(3, true)
        );
      } catch (e) {
        console.error(e);
        app.ports.reportFrontendError.send({
          module_: "builder",
          message: "Unable to get/set editor bookmark",
        });
      }
    };

    const editorSelection = editor.selection;

    if (editorSelection === null) {
      // exit since there's no selection
      return;
    }

    // eslint-disable-next-line fp/no-let
    let element = editorSelection.getNode();

    // In case of a table row reference, when the label is selected, since it is outside
    // of the transient box (it is a separate table row), we need to reselect the outer box
    // with the same ID
    if (isTransientLabelElement(element)) {
      return selectOuterBox(element);
    }

    const wrapperElement = getWrapperElement(element);
    const selectionContent = getSelectionContent();
    addTransientId(element);
    const parentHtml = deriveParentHtml(element);

    app.ports.builderUpdateSelection.send({
      selectionHtml: selectionContent,
      referenceHtml: wrapperElement && deriveReferenceHtml(wrapperElement),
      parentHtml,
    });

    setEditorBookmark();
  }, 100);

const handleTableModified = ({ table, style }) => {
  if (style) {
    if (table.style.borderWidth === "0px") {
      table.classList.add("ghostBorder");
    } else {
      table.classList.remove("ghostBorder");
    }
  }
};

const handleKeyDown =
  ({ editor, app }) =>
    (event) => {
      // Fix tinyMCE behavior which is not moving the caret position
      // inside option fields when user navigates with key arrows
      if (event.key == "ArrowRight" || event.key == "ArrowLeft") {
        const selectionElement = editor.selection.getNode();

        const fieldReferenceWithInnerFocusSupport =
          selectionElement &&
          selectionElement.getAttribute("data-teto-action") === "show";

        if (fieldReferenceWithInnerFocusSupport) {
          // The simple element.focus() is not synchronizing the TinyMCE
          // state. Also, using selection and collapse allows to specify the
          // begin/end caret position

          editor.selection.select(
            selectionElement.querySelector("[contenteditable='true']"),
            true
          );
          // Before we used collapse(e.key === "ArrowRight")
          // to set the cursor at the end of the Field Option content when
          // the user was moving from the right side, but that was causing
          // problems because TinyMCE can not set the cursor between the end
          // and another block not editable element.
          editor.selection.collapse(true);
        }
      } else {
        tetoShortcut(app)(event);
      }
    };

const selectionCells = (editor) => {
  const selectionContentString = editor.selection.getContent();
  // create an element from the string
  const element = document.createElement("div");
  element.innerHTML = selectionContentString;

  const cells = element.querySelectorAll("td");
  const cellIds = [...cells].map((cell) => cell.id);
  // for each id, get the corresponding td in the editor
  const editorBody = editor.getBody();
  const editorCells = cellIds.map((id) => editorBody.querySelector(`td#${id}`));

  return editorCells.filter((cell) => cell !== null);
};

const tableBeforeExecCommand = ({ editor, event }) => {
  if (event.command === "mceToggleFormat") {
    // We will find the selected cells, and if they have an hx class,
    // we will remove the <hx> tag.
    // We do this because tinymce treats the Heading styles different that the class
    // styles. So we end up having class and heading styles at the same time.
    // See T2-578
    const selectedCells = selectionCells(editor);

    selectedCells.forEach(removeEmptyHeadingElements);
    selectedCells.forEach(removeHeadingElementWrapper);
  }
};

const filterOutLabelRowCells = (cell) => {
  const tr = cell.closest("tr");
  return tr.getAttribute("data-teto-transient-label-container") === null;
};

const tableAfterExecCommand = ({ editor, event, styleFormats }) => {
  if (event.command === "mceToggleFormat") {
    const formatToBeApplied = event.value;

    const selectedCells = selectionCells(editor)
      // filter and cell that is inside a <tr> with a "data-teto-transient-label-container" attribute
      // we don't want to apply the class to those cells since they are labels
      .filter(filterOutLabelRowCells);

    cleanAndApplyFormat(styleFormats)({
      elements: selectedCells,
      classToApply: formatToBeApplied,
    });

    // clean up any empty <hx> tags
    selectedCells.forEach(removeEmptyHeadingElements);

    // if the user is applying an hx class
    if (formatToBeApplied.match(/^h[1-6]$/)) {
      // we will wrap each one of the selected cells with an <hx> tag
      selectedCells.forEach((cell) => {
        cell.innerHTML = `<${formatToBeApplied}>${cell.innerHTML}</${formatToBeApplied}>`;
      });
    }
  }
};

const toggleStyle = (html, style, name, value, tag) => {
  const valueToMutateTo =
    // if it starts with the tag given
    html.startsWith(tag)
      ? // mutate to the value
      value
      : // otherwise, if the value is same as the style given
      style[name] === value
        ? // mutate to null
        null
        : // otherwise, just mutate to the value given
        value;

  mutate(style, name, valueToMutateTo);
};

const getParagraphNode = (node) => {
  if (node === null) {
    return null;
  } else if (
    node.tagName === "P" ||
    node.tagName === "DIV" ||
    node.tagName === "H1" ||
    node.tagName === "H2" ||
    node.tagName === "H3" ||
    node.tagName === "H4" ||
    node.tagName === "H5" ||
    node.tagName === "H6" ||
    node.tagName === "TR" ||
    node.tagName === "TD"
  ) {
    return node;
  } else {
    return getParagraphNode(node.parentNode);
  }
};

const textBeforeExecCommand = ({ editor, styleFormats, event }) => {
  if (event.command === "mceToggleFormat") {
    const formatToBeApplied = event.value;
    const nodes = getNodesSelected(editor);

    // if the user is applying an hx format
    if (formatToBeApplied.match(/^h[1-6]$/)) {
      // if the element has any class from the styles, remove it
      cleanAndApplyFormat(styleFormats)({
        elements: nodes,
        classToApply: "",
      });
    }
  }
};

const textAfterExecCommand = ({ editor, styleFormats, event }) => {
  if (event.command === "mceToggleFormat") {
    const nodes = getNodesSelected(editor);

    const isOneNodeSelected = nodes.length !== 1;
    const isReferenceSelected =
      isOneNodeSelected && nodes[0].tagName !== "SPAN";

    // if the node selected is NOT a reference, exit!
    if (!isReferenceSelected) {
      return;
    }
  }

  const styleFormat = styleFormats.find((sf) => sf.format === event.value);

  const formatIsHeader = (format) => {
    return (
      format == "h1" ||
      format == "h2" ||
      format == "h3" ||
      format == "h4" ||
      format == "h5" ||
      format == "h6"
    );
  };

  const formatFieldWithP = (format, node) => {
    if (formatIsHeader(format)) {
      removeOtherStyleFormats(node);
      editor.selection.select(changeElementTag(node, format));
    } else if (node.classList.contains(format)) {
      node.classList.remove(format);
      node.classList.add("Normal");
    } else {
      removeOtherStyleFormats(node);
      node.classList.add(format);
    }
  };

  const formatFieldWithDiv = (format, node) => {
    if (formatIsHeader(format)) {
      removeOtherStyleFormats(node);
      editor.selection.select(changeElementTag(node, format));
    } else {
      node.classList.toggle(format);
    }
  };

  const formatFieldWithSpan = (format, node) => {
    const findParent = (node) => {
      if (node.parentNode.tagName === "BODY") {
        return null;
      } else if (
        node.parentNode.tagName === "P" ||
        node.parentNode.tagName === "DIV" ||
        node.parentNode.tagName === "H1" ||
        node.parentNode.tagName === "H2" ||
        node.parentNode.tagName === "H3" ||
        node.parentNode.tagName === "H4" ||
        node.parentNode.tagName === "H5" ||
        node.parentNode.tagName === "H6"
      ) {
        return node.parentNode;
      } else {
        return findParent(node.parentNode);
      }
    };
    const parent = findParent(node);
    if (parent) {
      formatField(format, parent);
    }
  };

  const formatFieldWithHeader = (format, node) => {
    if (!formatIsHeader(format)) {
      editor.selection.select(changeElementTag(node, "p"));
      editor.selection.getNode().classList.add(format);
    } else if (format === node.tagName.toLowerCase()) {
      editor.selection.select(changeElementTag(node, "p"));
      editor.selection.getNode().classList.add("Normal");
    } else {
      editor.selection.select(changeElementTag(node, format));
    }
  };

  const formatField = (format, node) => {
    switch (tagName) {
      case "P":
        formatFieldWithP(format, node);
        break;
      case "DIV":
        formatFieldWithDiv(format, node);
        break;
      case "H1":
      case "H2":
      case "H3":
      case "H4":
      case "H5":
      case "H6":
        formatFieldWithHeader(format, node);
        break;
      case "SPAN":
        formatFieldWithSpan(format, node);
    }
  };

  const changeElementTag = (element, tagName) => {
    const newElement = document.createElement(tagName);

    // Copy the children
    const appendChild = (e) => {
      if (e.firstChild) {
        newElement.appendChild(e.firstChild); // Moves the child
        appendChild(e);
      }
    };
    appendChild(element);

    // Copy the attributes
    const copyAttributes = (index) => {
      if (index >= 0) {
        newElement.attributes.setNamedItem(
          element.attributes[index].cloneNode()
        );
        copyAttributes(index - 1);
      }
    };
    copyAttributes(element.attributes.length - 1);

    // Replace it
    element.parentNode.replaceChild(newElement, element);

    return newElement;
  };

  const removeOtherStyleFormats = (
    node,
    currentStyle = undefined,
    index = 0
  ) => {
    if (node === null) {
      return;
    } else if (index < styleFormats.length) {
      const styleFormat = styleFormats[index];
      if (
        (!currentStyle || currentStyle !== styleFormat) &&
        node.classList.contains(styleFormat.format)
      ) {
        node.classList.remove(styleFormat.format);
      }
      removeOtherStyleFormats(node, currentStyle, index + 1);
    }
  };

  // When selection is a field without paragraph element
  // Example: <span contenteditable="false"></span>
  // and the format command is style
  if (
    event.command === "mceToggleFormat" &&
    styleFormat &&
    node.getAttribute("contenteditable") === "false"
  ) {
    formatField(event.value, node);
  } else if (
    event.command === "mceToggleFormat" ||
    event.command === "mceApplyTextcolor" ||
    event.command === "mceRemoveTextcolor" ||
    event.command === "FontName" ||
    event.command === "FontSize" ||
    event.command === "RemoveFormat"
  ) {
    // TinyMCE doesn't apply format to elements with [contenteditable="false"]
    // therefore the style is appplied directly to each not-editable element

    // A temporal DOM for the selection is created because tinyMCE can
    // returns an incorrect DOM if the selection starts from the middle of
    // a Dom tag
    //
    // Example:
    // <div id="a">wordA
    //    <div id="b" contenteditable="false">wordB</div>
    //    wordC
    //    <div id="c" contenteditable="false">wordD</div>
    //    wordE
    // <div>
    //
    // If the selection starts in `wordC`, `wordC` is not at the begin of
    // the container tag, so the selection DOM of tinyMCE will include
    // the `div` with `id="b"` which is not part of the user selection.
    //
    // With temporal DOM we make sure to get only the
    // `contenteditable="false"` elements which are part of the selection
    const html = editor.selection.getContent();
    const newDom = new DOMParser().parseFromString(html, "text/html").body;
    const notEditableElements = newDom.querySelectorAll(
      '[contenteditable="false"]'
    );

    if (notEditableElements.length > 0) {
      notEditableElements.forEach((element) => {
        const style = element.style;
        switch (event.command) {
          case "mceToggleFormat":
            switch (event.value) {
              case "bold":
                toggleStyle(html, style, "font-weight", "bold", "<strong>");
                break;
              case "italic":
                toggleStyle(html, style, "font-style", "italic", "<em>");
                break;
            }
            break;
          case "mceApplyTextcolor":
            switch (event.ui) {
              case "forecolor":
                mutate(style, "color", event.value);
                break;
              case "hilitecolor":
                mutate(style, "background-color", event.value);
                break;
            }
            break;
          case "mceRemoveTextcolor":
            switch (event.ui) {
              case "forecolor":
                mutate(style, "color", null);
                break;
              case "hilitecolor":
                mutate(style, "background-color", null);
                break;
            }
            break;
          case "FontName":
            mutate(style, "font-family", event.value);
            break;
          case "FontSize":
            mutate(style, "font-size", event.value);
            break;
          case "RemoveFormat":
            [
              "font-weight",
              "font-style",
              "color",
              "background-color",
              "font-family",
              "font-size",
            ].forEach((key) => {
              mutate(style, key, null);
            });
        }
      });

      const bookmark = editor.selection.getBookmark();
      editor.selection.setContent(newDom.innerHTML);
      editor.selection.moveToBookmark(bookmark);
    }

    // This is to prevent multiple styles at the same time
    if (event.command === "mceToggleFormat" && styleFormat) {
      removeOtherStyleFormats(getParagraphNode(node), styleFormat);
      const selectionNode = node;
      if (
        selectionNode.tagName === "P" &&
        !styleFormats.some((sf) => selectionNode.classList.contains(sf.format))
      ) {
        selectionNode.classList.add("Normal");
      }
    }
  }
};

// returns a list of all the nodes selected
// ( tinymce returns lines! not specific text etc. for example it will return a whole <p> even
// if you just selected one letter in that <p> line)
const getNodesSelected = (editor) => {
  return editor.selection.getSelectedBlocks();
};

const beforeExecCommand =
  ({ editor, styleFormats }) =>
    (event) => {
      const blocks = getNodesSelected(editor);
      // when the style format is for table cells, the nodes selected will always return
      // the one <td> the user ended the selection in
      const styleFormatInTable =
        blocks.length === 1 &&
        blocks[0].tagName === "TD" &&
        styleFormats.find((sf) => sf.format === event.value);

      if (styleFormatInTable) {
        tableBeforeExecCommand({ editor, event });
      } else {
        textBeforeExecCommand({ editor, styleFormats, event });
      }
    };

const afterExecCommand =
  ({ editor, styleFormats }) =>
    (event) => {
      // 2 cells or more
      if (editor.selection.getContent().startsWith("<table ")) {
        tableAfterExecCommand({ editor, event, styleFormats });
      } else {
        textAfterExecCommand({ editor, styleFormats, event });
      }

      if (event.command === "mceInsertTable") {
        const selectionParentNode = editor.selection.getNode();
        // add the class "Normal" to the table <td> elements
        const table =
          selectionParentNode.tagName === "TABLE"
            ? selectionParentNode
            : selectionParentNode.tagName === "TD" ||
              selectionParentNode.tagName === "TR"
              ? // find the table element in the parent nodes
              selectionParentNode.closest("table")
              : null;

        if (!table) {
          throw new Error("Table not found");
        }

        const tds = table.querySelectorAll("td");
        tds.forEach((td) => {
          td.classList.add("Normal");
        });
      }
    };

//---------------------------------------------------------------------------------------

export const builderInit = ({
  editor,
  readOnly,
  app,
  styleFormats,
  editorBookmark,
}) => {
  if (!readOnly) {
    editor.ui.registry.addMenuItem('comment', {
      text: "Comment",
      context: "tools",
      onAction: function () {
        app.ports.builderAddComment.send(null);
      }
    });
  }

  if (readOnly) {
    editor.mode.set("readonly");
  } else {
    editor.focus();
  }

  editor.dom
    .select(
      "[data-teto-transient-box-outer], [data-teto-transient-box-inner], [data-teto-transient-box-label]"
    )
    .forEach(addTransientId);

  // Add click listeners to comment elements.
  editor.dom.select("[data-teto-comment-thread]")
    .forEach((comment) => {
      comment.addEventListener("click", function (e) {
        e.stopPropagation();
        if (editor.dom.select('body')[0].classList.contains("comments-visible")) {
          app.ports.builderCommentThreadClicked.send(parseInt(comment.dataset.tetoCommentThreadId));
        }
      });
    });

  if (editorBookmark) {
    editor.selection.moveToBookmark(editorBookmark);
    const elem = editor.selection.getNode();
    scrollWithin(elem.closest("html"), elem);
  }

  editor.on("Dirty", handleDirty({ app, editor }));
  editor.on("focus", handleFocus(app));
  editor.on("PastePostProcess", handlePastePostProcess({ app, editor, styleFormats }));
  editor.on("NodeChange", handleNodeChange({ editor, styleFormats }));
  editor.on("SelectionChange", handleSelectionChange({ app, editor })); // TODO: Rethink the logic to speed up editing.
  editor.on("TableModified", handleTableModified);
  editor.on("Keydown", handleKeyDown({ editor, app }));
  editor.on("BeforeExecCommand", beforeExecCommand({ editor, styleFormats }));
  editor.on("ExecCommand", afterExecCommand({ editor, styleFormats }));

  sendEditorContentsToElm(app)(editor, [], [], false)
};
