import EasyMDE from "easymde";

class MarkdownEditor extends HTMLElement {
  constructor() {
    super();
    this._value = "";
  }

  connectedCallback() {
    this.addEasyMde();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === "input") {
      if (this._editor && this._editor.value() !== newValue) {
        this._editor.value(newValue);
      }
    }
  }

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
  }

  static get observedAttributes() {
    return ["textarea-id", "input", "placeholder"];
  }

  addEasyMde() {
    const textareaId = this.getAttribute("textarea-id");
    const input = this.getAttribute("input");
    const placeholder = this.getAttribute("placeholder");
    const readOnly = this.getAttribute("readOnly");

    let easymde = new EasyMDE({
      element: document.getElementById(textareaId),
      toolbar: [
        "bold",
        "italic",
        "heading",
        "|",
        "unordered-list",
        "ordered-list",
        "link",
        "table",
        "quote",
        "|",
        "undo",
        "redo",
        "|",
        "preview",
        "guide",
      ],
      shortcuts: { drawLink: null },
      initialValue: input,
      placeholder: placeholder,
      maxHeight: "250px",
      spellChecker: false,
      status: false,
      readOnly: readOnly === "true",
    });

    easymde.codemirror.on("change", () => {
      this._value = easymde.value();
      this.dispatchEvent(new CustomEvent("change"));
    });

    easymde.codemirror.on("focus", () => {
      this.dispatchEvent(new CustomEvent("focus"));
    });

    if (readOnly === "true") {
      easymde.codemirror.setOption("readOnly", true);
    }

    this._editor = easymde;
  }
}

customElements.define("markdown-editor", MarkdownEditor);
